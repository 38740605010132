import React, { useState } from 'react';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faTerminal } from '@fortawesome/free-solid-svg-icons/faTerminal';
import po2json from 'po2json/lib/parse';
import { ControlButton, ControlGrid, ControlLabel, Text } from '../../Controls';
import TextFileReader from '../../Controls/TextFileReader';
import { download } from '../../../utils';

export type TranslatorProps = {
	defaultLocale?: string;
	defaultDomain?: string;
	defaultHandle: string;
	canModifyDomainAndHandle?: boolean;
};
export default function Translator(props: TranslatorProps) {
	const {
		defaultHandle,
		canModifyDomainAndHandle = false,
		defaultLocale = 'de_DE',
		defaultDomain = 'wp-eform',
	} = props;

	const [poValue, setPoValue] = useState<string>('');
	const [locale, setLocale] = useState<string>(defaultLocale);
	const [domain, setDomain] = useState<string>(defaultDomain);
	const [handle, setHandle] = useState<string>(defaultHandle);

	return (
		<ControlGrid>
			<ControlGrid.Title>
				Make WordPress compatible jed json from po
			</ControlGrid.Title>
			{canModifyDomainAndHandle ? (
				<>
					<ControlGrid.Half>
						<ControlLabel label="Script Handle" required>
							<Text
								type="text"
								value={handle}
								onChange={e => {
									setHandle(e.target.value);
								}}
								icon={faTerminal}
							/>
						</ControlLabel>
					</ControlGrid.Half>
					<ControlGrid.Half>
						<ControlLabel label="Translation Domain" required>
							<Text
								type="text"
								value={domain}
								onChange={e => {
									setDomain(e.target.value);
								}}
								icon={faTerminal}
							/>
						</ControlLabel>
					</ControlGrid.Half>
				</>
			) : null}
			<ControlGrid.Full>
				<ControlLabel label="Locale Id" required>
					<Text
						type="text"
						value={locale}
						onChange={e => {
							setLocale(e.target.value);
						}}
						icon={faFlag}
					/>
				</ControlLabel>
			</ControlGrid.Full>
			<ControlGrid.Full>
				<ControlLabel label="Enter PO file content" htmlFor={handle} required>
					<TextFileReader
						value={poValue}
						onChange={setPoValue}
						name={handle}
						label="To upload a po file"
						accept="text/x-gettext-translation, text/plain"
					/>
				</ControlLabel>
			</ControlGrid.Full>
			<ControlGrid.Full>
				<ControlButton
					onClick={e => {
						e.preventDefault();
						if (handle === '') {
							alert('Handle cannot be empty.');
							return;
						}
						if (domain === '') {
							alert('Domain cannot be empty.');
							return;
						}
						if (locale === '') {
							alert('Locale cannot be empty.');
							return;
						}
						if (poValue === '') {
							alert('Please select a po file.');
							return;
						}
						const result = po2json(poValue, {
							pretty: true,
							stringify: true,
							format: 'jed1.x',
							domain,
						});
						download(`${domain}-${locale}-${handle}.json`, result);
					}}
				>
					Generate JSON
				</ControlButton>
			</ControlGrid.Full>
		</ControlGrid>
	);
}
