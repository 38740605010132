import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import {
	buttonResetCss,
	getMultipleTransitionsWithWillChange,
	gv,
	ns,
} from '../../../utils';
import classNames from 'classnames';

const Btn = styled.button`
	${buttonResetCss};
	background: linear-gradient(
		-225deg,
		${gv('backgroundShade')},
		${gv('disabledBackgroundColor')}
	);
	border-radius: ${props => ns(props.theme.borderRadiusBase * 1.5)};
	box-shadow: inset 0 -6px 0 0 ${gv('borderColorLight')},
		inset 0 0 2px 2px ${gv('appBackgroundColor')},
		0 2px 6px 1px rgba(30, 35, 90, 0.4);
	&:hover {
		box-shadow: inset 0 -6px 0 0 ${gv('borderColorLight')},
			inset 0 0 1px 1px ${gv('appBackgroundColor')},
			0 1px 2px 1px rgba(30, 35, 90, 0.4);
	}
	&:focus-visible {
		outline: 2px outset ${gv('controlShadowPrimary')};
	}
	&:active {
		box-shadow: inset 0 -3px 0 0 ${gv('borderColorLight')},
			inset 0 0 1px 1px ${gv('appBackgroundColor')},
			0 1px 2px 1px rgba(30, 35, 90, 0.4);
	}
	color: ${gv('textColorSecondary')};
	font-family: ${props => props.theme.fontFamilyMono};
	font-size: ${props => ns(props.theme.controlHeightBase)};
	display: inline-flex;
	height: ${props => ns(props.theme.controlHeightBase * 1.5)};
	min-width: ${props => ns(props.theme.controlHeightBase * 1.5)};
	text-align: center;
	justify-content: center;
	align-items: center;
	&.wide {
		min-width: ${props => ns(props.theme.controlHeightBase * 3)};
		padding-left: ${props => ns(props.theme.gutter)};
		justify-content: flex-start;
		text-align: left;
	}
	margin: 0 0.4em;
	padding: 0 0.5em;
`;

const CopyMessage = styled.span`
	position: absolute;
	top: -20px;
	background-color: ${gv('textColorSecondary')};
	color: ${gv('appBackgroundColor')};
	padding: ${props => ns(props.theme.gutter * 0.25)}
		${props => ns(props.theme.gutter)};
	text-align: center;
	font-size: ${props => ns(props.theme.fz.small2)};
	font-weight: bold;
	border-radius: ${props => ns(props.theme.borderRadiusBase)};
	opacity: 0;
	transform: translateX(-20px);
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['opacity', 'transform'],
			props.theme.transitionControl
		)};
	&.active {
		opacity: 1;
		transform: translateX(0px);
	}
`;

const BtnContainer = styled.div`
	height: 100px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const Label = styled.p`
	&&&&&& {
		margin: 0;
		padding: ${props => ns(props.theme.gutter * 0.5)}
			${props => ns(props.theme.gutter)};
		border-radius: ${props => ns(props.theme.borderRadiusBase)};
		background-color: ${gv('backgroundShade')};
		font-size: ${props => ns(props.theme.fz.small2)};
		text-transform: uppercase;
		font-weight: bold;
		line-height: 1;
		text-align: center;
		&.unicode {
			text-transform: lowercase;
			font-family: ${props => props.theme.fontFamilyMono};
			margin: 4px 0 0 0;
		}
	}
`;

const Item = styled.div`
	border: 1px solid ${gv('borderColorLight')};
	background-color: ${gv('appBackgroundColor')};
	border-radius: ${props => ns(props.theme.borderRadiusBase)};
	padding: ${props => ns(props.theme.gutter * 0.5)};
	box-shadow: ${props => props.theme.boxShadowBottom};
`;

const Container = styled.div`
	margin: ${props => ns(props.theme.gutter * 2)} 0;
	display: grid;
	grid-template-columns: repeat(
		auto-fit,
		minmax(
			${props =>
				ns(props.theme.controlHeightBase * 3 + props.theme.gutter * 2)},
			1fr
		)
	);
	grid-gap: ${props => ns(props.theme.gutter)};
`;

type SymbolType = {
	symbol: string;
	label: string;
	isWide?: boolean;
};

const symbols: SymbolType[] = [
	{
		symbol: '⌘',
		label: 'Command',
	},
	{
		symbol: '^',
		label: 'Control',
	},
	{
		symbol: '⇧',
		label: 'Shift',
		isWide: true,
	},
	{
		symbol: '⎇',
		label: 'Alt',
	},
	{
		symbol: '⌥',
		label: 'Option',
	},
	{
		symbol: '⏎',
		label: 'Return',
	},
	{
		symbol: '⌫',
		label: 'delete',
		isWide: true,
	},
	{
		symbol: '⇪',
		label: 'caps lock',
		isWide: true,
	},
	{
		symbol: '↹',
		label: 'Tab',
		isWide: true,
	},
	{
		symbol: '↑',
		label: 'up',
	},
	{
		symbol: '↓',
		label: 'down',
	},
	{
		symbol: '←',
		label: 'left',
	},
	{
		symbol: '→',
		label: 'right',
	},
];

function CopyButton(props: { symbol: SymbolType }) {
	const { symbol } = props;
	const [copied, setCopied] = useState<boolean>(false);
	useEffect(() => {
		if (typeof window !== 'undefined') {
			let mounted = true;
			const timerId = setTimeout(() => {
				if (mounted) {
					setCopied(false);
				}
			}, 2000);
			return () => {
				clearTimeout(timerId);
				mounted = false;
			};
		}
		return () => {};
	}, [copied]);
	return (
		<BtnContainer>
			<CopyMessage className={classNames({ active: copied })}>
				Copied!
			</CopyMessage>
			<Btn
				onClick={e => {
					e.preventDefault();
					copy(symbol.symbol);
					setCopied(true);
				}}
				className={classNames({ wide: symbol.isWide })}
			>
				{symbol.symbol}
			</Btn>
		</BtnContainer>
	);
}

export default function KeyboardSymbols() {
	return (
		<Container>
			{symbols.map(symbol => (
				<Item key={symbol.symbol}>
					<CopyButton symbol={symbol} />
					<Label>{symbol.label}</Label>
				</Item>
			))}
		</Container>
	);
}
