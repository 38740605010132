import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { gv, ns } from '../../utils';

const Item = styled.li`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: ${props => ns(props.theme.gutter * 0.5)} 0;

	.icon {
		width: ${props => ns(props.theme.fz.large1 * 1.5)};
		flex: 0 0 ${props => ns(props.theme.fz.large1 * 1.5)};
		font-size: ${props => ns(props.theme.fz.base)};
		line-height: ${props => ns(props.theme.fz.large1 * 1.5)};
		color: ${gv('linkColor')};
	}
	.text {
		width: calc(100% - ${props => ns(props.theme.fz.large1 * 1.5)});
		flex: 0 0 calc(100% - ${props => ns(props.theme.fz.large1 * 1.5)});
		padding-left: 0.25em;
	}
`;

export const UlList = styled.ul`
	&&& {
		list-style: none;
		margin: 1.5em 0 1.5em 1.5em;
		padding: 0;
		ul,
		ol {
			margin-top: 0.25em;
			margin-bottom: 0.25em;
			margin-left: 1em;
		}
		&.attentive {
			${Item} {
				padding: ${props => ns(props.theme.gutter * 0.25)} 0;
				line-height: 1.5;
				color: ${gv('proseColor')};
				font-size: ${props => ns(props.theme.fz.large1)};
				.icon {
					color: ${gv('successColor')};
				}
			}
		}
	}
`;

export const OlList = styled.ol`
	&&& {
		list-style: decimal;
		margin: 1.5em 0 1.5em 1.5em;
		padding: 0;
		list-style: none;
		counter-reset: item;
		ul,
		ol {
			margin-top: 0.25em;
			margin-bottom: 0.25em;
			margin-left: 1em;
		}
		${Item} {
			counter-increment: item;
			&::before {
				content: counter(item);
				display: inline-block;
				width: ${props => ns(props.theme.fz.large1 * 1)};
				margin: 0 ${props => ns(props.theme.fz.large1 * 0.5)} 0 0;
				flex: 0 0 ${props => ns(props.theme.fz.large1 * 1)};
				font-size: ${props => ns(props.theme.fz.base)};
				line-height: ${props => ns(props.theme.fz.base * 1.5)};
				font-family: ${props => props.theme.fontFamilyLogo};
				font-size: ${props => ns(props.theme.fz.base)};
				color: ${gv('linkColor')};
				border-right: 2px solid ${gv('linkColor')};
				font-weight: bold;
				border-radius: 2px;
			}
			.icon {
				display: none;
			}
			.text {
				width: calc(100% - ${props => ns(props.theme.fz.large1 * 1.5)});
			}
		}
	}
`;

type ArrowType = 'right' | 'check';

export function ListItem(props: PropsWithChildren<{ arrow?: ArrowType }>) {
	const { arrow = 'right', children } = props;
	return (
		<Item>
			<span className="icon">
				<FontAwesomeIcon
					icon={arrow === 'right' ? faArrowRight : faCheck}
					aria-hidden
				/>
			</span>
			<div className="text">{children}</div>
		</Item>
	);
}
