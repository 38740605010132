import React, { useState } from 'react';
import dayjs from 'dayjs';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import {
	ControlButton,
	ControlCopyDisplay,
	ControlGrid,
	ControlLabel,
	Text,
} from '../../Controls';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import TipBox from '../../Typography/TipBox';
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

export default function DateTimeTool() {
	const [currentTime, setCurrentTime] = useState<number>(dayjs().unix());
	const [format, setFormat] = useState<string>('dddd, MMMM D, YYYY h:mm A	');

	return (
		<ControlGrid>
			<ControlGrid.Title>Date Time Tooling</ControlGrid.Title>
			<ControlGrid.Full>
				<ControlLabel
					label="Input unix timestamp (seconds since epoc)"
					required
				>
					<Text
						icon={faCalendar}
						type="tel"
						min={0}
						step={100}
						value={currentTime}
						onChange={e => {
							let parsedValue = Number.parseInt(e.target.value);
							if (Number.isNaN(parsedValue)) {
								parsedValue = 0;
							}
							if (parsedValue < 0) {
								parsedValue = 0;
							}
							setCurrentTime(parsedValue);
						}}
					/>
				</ControlLabel>
			</ControlGrid.Full>
			<ControlGrid.Full>
				<ControlLabel label="Or using a datetime picker (UTC)">
					<Text
						icon={faCalendar}
						type="datetime-local"
						value={dayjs.unix(currentTime).utc().format('YYYY-MM-DDThh:mm')}
						placeholder="or select datetime"
						onChange={e => {
							setCurrentTime(
								dayjs.utc(e.target.value, 'YYYY-MM-DDThh:mm').unix()
							);
						}}
					/>
					<ControlButton
						className="small secondary"
						onClick={e => {
							e.preventDefault();
							setCurrentTime(dayjs().unix());
						}}
						style={{ marginTop: '20px' }}
					>
						current
					</ControlButton>
				</ControlLabel>
			</ControlGrid.Full>
			<ControlGrid.Half>
				<ControlLabel label="UTC ISO 8601">
					<ControlCopyDisplay
						size="regular"
						text={dayjs.unix(currentTime).utc().toISOString()}
					/>
				</ControlLabel>
			</ControlGrid.Half>
			<ControlGrid.Half>
				<ControlLabel label="UTC mySQL DateTime">
					<ControlCopyDisplay
						size="regular"
						text={dayjs.unix(currentTime).utc().format('YYYY-MM-DD HH:mm:ss')}
					/>
				</ControlLabel>
			</ControlGrid.Half>
			<ControlGrid.Full>
				<ControlLabel label="Local DateTime">
					<ControlCopyDisplay
						size="regular"
						text={dayjs.unix(currentTime).format('LLLL')}
					/>
				</ControlLabel>
			</ControlGrid.Full>
			<ControlGrid.Half>
				<ControlLabel label="Local Date">
					<ControlCopyDisplay
						size="regular"
						text={dayjs.unix(currentTime).format('LL')}
					/>
				</ControlLabel>
			</ControlGrid.Half>
			<ControlGrid.Half>
				<ControlLabel label="Local Time">
					<ControlCopyDisplay
						size="regular"
						text={dayjs.unix(currentTime).format('LT')}
					/>
				</ControlLabel>
			</ControlGrid.Half>
			<ControlGrid.Full>
				<TipBox>
					<p>
						All date time format string from{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://day.js.org/docs/en/display/format"
						>
							Dayjs Basic Format
						</a>
						,{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://day.js.org/docs/en/display/format#localized-formats"
						>
							Localized Format
						</a>{' '}
						and{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://day.js.org/docs/en/plugin/advanced-format"
						>
							Advanced Format
						</a>{' '}
						are available. Escape format characters inside{' '}
						<code>[&lt;format characters&gt;]</code>, like{' '}
						<code>MMMM D, [at] h:mm A</code>.
					</p>
				</TipBox>
			</ControlGrid.Full>
			<ControlGrid.Full>
				<ControlLabel label="Date Time Format" required>
					<Text
						icon={faCalendar}
						value={format}
						onChange={e => {
							setFormat(e.target.value);
						}}
						type="text"
					/>
				</ControlLabel>
			</ControlGrid.Full>
			<ControlGrid.Full>
				<ControlLabel label="Formatted output (Local)">
					<ControlCopyDisplay
						size="regular"
						text={dayjs.unix(currentTime).format(format)}
					/>
				</ControlLabel>
			</ControlGrid.Full>
			<ControlGrid.Full>
				<ControlLabel label="Formatted output (UTC)">
					<ControlCopyDisplay
						size="regular"
						text={dayjs.unix(currentTime).utc().format(format)}
					/>
				</ControlLabel>
			</ControlGrid.Full>
		</ControlGrid>
	);
}
