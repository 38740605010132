import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql, PageProps } from 'gatsby';
import {
	getMultipleTransitionsWithWillChange,
	ns,
	screenReaderText,
	gv,
	bp,
	buttonResetCss,
} from '../../utils';
import { ReactComponent as MenuSvg } from './menu.svg';
import classNames from 'classnames';
import { globalHistory } from '@reach/router';
import { Constrain } from '../Containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import Search from '../Search';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import LogoSvg from '../Logo';

const HeaderContainer = styled.header`
	position: sticky;
	top: 0;
	z-index: 10000;
	height: ${props => ns(props.theme.controlHeightBase * 1.5)};
	background-color: ${gv('appBackgroundColor')};
	color: ${gv('textColor')};
	box-shadow: ${props => props.theme.boxShadowBottom};
	overflow-x: auto;
	overflow-y: hidden;
`;

const HeaderInner = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: 0 ${props => ns(props.theme.gutter * 0.25)};
`;

const Logo = styled(LogoSvg)`
	height: ${props => ns(props.theme.controlHeightBase * 0.8)};
	width: auto;
	margin: 0 ${props => ns(props.theme.gutter * 0.25)} 0 0;
`;

const HiddenTitle = styled.span`
	${screenReaderText};
`;

const Title = styled(Link)`
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: center;
	max-width: 150px;
	font-size: ${props => ns(props.theme.fz.large2)};
	line-height: 1;
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.tablet)}) {
		max-width: 300px;
		font-size: ${props => ns(props.theme.fz.large2 + 4)};
	}
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;

const LogoContainer = styled.div`
	flex: 0 0 auto;
	color: ${gv('headingColor')};
	padding: ${props => ns(props.theme.controlHeightBase * 0.35)};
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	a {
		color: ${gv('headingColor')};
		&:visited,
		&:hover {
			color: ${gv('headingColor')};
		}
		text-decoration: none;
	}
`;

const LinkInner = styled.span``;

const ActionLink = styled.a`
	${buttonResetCss};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: ${props => ns(props.theme.controlHeightBase * 1)};
	min-width: ${props => ns(props.theme.controlHeightBase * 0.75)};
	padding: 0 ${props => ns(props.theme.gutter * 0.75)};
	margin: 0;
	text-align: center;
	font-size: ${props => ns(props.theme.fz.base)};
	font-family: ${props => props.theme.fontFamilyHeading};
	font-weight: bold;
	text-decoration: none;
	position: relative;
	color: ${gv('headingColor')};
	text-decoration: none;
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['border-color'],
			props.theme.transitionControl
		)};

	&:hover {
		color: ${gv('textColor')};
		text-decoration: underline;
	}
	&:active,
	&:focus {
		color: ${gv('textColor')};
	}
	&.is-icon {
		padding: 0;
	}
	svg {
		height: 1.25em;
		width: 1.25em;
		display: block;
		fill: currentColor;
	}
	&.active {
		color: ${gv('textColor')};
		text-decoration: underline;
	}
	&.highlighted {
		color: ${gv('textColor')};
		text-decoration: underline;
	}

	.icon-after {
		margin-right: ${props => ns(props.theme.gutter * 0.25)};
	}
	.small-icon {
		font-size: 0.75em;
	}
	@media ${props => bp(props, 'desktop')} {
		.mobile-only {
			border: 0;
			clip: rect(1px, 1px, 1px, 1px);
			clip-path: inset(50%);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
			word-wrap: normal !important;
		}
		&.separator {
			margin-left: ${props => ns(props.theme.gutter * 1.5)};
		}
	}
`;
const PriorityActions = styled.div`
	padding: 0 ${props => ns(props.theme.gutter)} 0 0;
	height: ${props => ns(props.theme.controlHeightBase * 1.5)};
	--dn-background-color: ${gv('appBackgroundColor')};
	margin: 0 0 0 auto;
	display: flex;
	align-items: center;
	> * {
		flex: 0 0 auto;
	}
	@media ${props => bp(props, 'desktop')} {
		margin: 0;
		.opens-menu {
			display: none;
		}
		position: relative;
		padding-left: ${props => ns(props.theme.gutter * 0.5)};
		margin-left: ${props => ns(props.theme.gutter * 0.5)};
		&::before {
			position: absolute;
			left: 0;
			width: 1px;
			display: block;
			content: '';
			background-color: ${gv('borderColorLight')};
			top: 20%;
			bottom: 20%;
		}
	}
`;

const ConditionalContainer = styled.div`
	&.mobile-only {
		@media ${props => bp(props, 'desktop')} {
			display: none;
		}
	}
	&.desktop-only {
		display: none;
		@media ${props => bp(props, 'desktop')} {
			display: initial;
		}
	}
`;

const NavigationOverlay = styled.div`
	position: fixed;
	top: ${props => ns(props.theme.controlHeightBase * 1.5)};
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	z-index: 999998;
	opacity: 0;
	backdrop-filter: blur(0px);
	background-color: rgba(0, 0, 0, 0);
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['opacity', 'background-color', 'backdrop-filter'],
			props.theme.transition
		)};
	&.open {
		opacity: 1;
		backdrop-filter: blur(10px);
		background-color: rgba(0, 0, 0, 0.75);
		pointer-events: initial;
	}
	@media ${props => bp(props, 'desktop')} {
		display: none;
	}
`;

const Navigation = styled.nav`
	@media screen and (max-width: ${props =>
			ns(props.theme.breakpoints.desktop - 1)}) {
		position: fixed;
		top: ${props => ns(props.theme.controlHeightBase * 1.5)};
		left: -300px;
		width: 300px;
		height: 100%;
		bottom: 0;
		background-color: ${gv('backgroundShade')};
		--dn-background-color: ${gv('backgroundShade')};
		z-index: 999999;
		opacity: 0.5;
		transition: ${props =>
			getMultipleTransitionsWithWillChange(
				['opacity', 'left'],
				props.theme.transitionBeizer
			)};
		&.open {
			left: 0;
			opacity: 1;
		}
		box-shadow: ${props => props.theme.boxShadowRight};

		padding: ${props => ns(props.theme.gutter)};
		overflow: auto;
		${ActionLink} {
			color: ${gv('textColor')};
			border-color: ${gv('controlShadowGhost')};
			margin: 0 0 ${props => ns(props.theme.gutter)};
			width: 100%;
		}
		${ConditionalContainer} {
			position: absolute;
			top: ${props => ns(props.theme.gutter * 0.5)};
			right: ${props => ns(props.theme.gutter * 0.5)};
		}
	}
	@media ${props => bp(props, 'desktop')} {
		margin-left: auto;
		display: flex;
		align-items: center;
	}
`;

const navLinks = [
	{
		to: '/features/',
		label: 'Features',
	},
	{
		to: '/pricing/',
		label: 'Pricing',
	},
	{
		to: '/examples/',
		label: 'Examples',
	},
	{
		to: '/docs/',
		label: 'Help',
	},
];
const secondaryNavLinks = [
	{
		to: '/contact/',
		label: 'Contact sales',
	},
	{
		to: '/account/',
		label: 'Log in',
	},
];

export type HeaderProps = {
	actions: ReactNode;
	location: PageProps['location'];
};
export default function Header(props: HeaderProps) {
	const data = useStaticQuery<GatsbyTypes.HeaderDataQuery>(graphql`
		query HeaderData {
			site {
				siteMetadata {
					title
					social {
						twitter
					}
				}
			}
		}
	`);
	const [navOpen, setNavOpen] = useState<boolean>(false);
	const navRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (typeof window === 'undefined') {
			return () => {};
		}
		const handler = (event: MouseEvent) => {
			if (
				!navRef.current ||
				navRef.current.isSameNode(event.target as HTMLElement) ||
				navRef.current.contains(event.target as HTMLElement)
			) {
				return;
			}
			setNavOpen(false);
		};
		document.addEventListener('click', handler);
		return () => {
			document.removeEventListener('click', handler);
		};
	}, []);
	useEffect(() => {
		return globalHistory.listen(({ action }) => {
			if (action === 'PUSH') {
				setNavOpen(false);
			}
		});
	}, []);
	useEffect(() => {
		if (typeof window === 'undefined') {
			return;
		}
		if (navOpen) {
			document.body.classList.add('ReactModal__Body--open');
		} else {
			document.body.classList.remove('ReactModal__Body--open');
		}
	}, [navOpen]);

	const { location, actions } = props;

	return (
		<HeaderContainer>
			<Constrain>
				<HeaderInner>
					<LogoContainer>
						<Title to="/">
							<Logo aria-hidden />
							<HiddenTitle>{data.site?.siteMetadata?.title}</HiddenTitle>
							<LogoSvg.Text hasIo />
						</Title>
					</LogoContainer>
					<Navigation className={classNames({ open: navOpen })} ref={navRef}>
						{navLinks.map(nl => (
							<ActionLink
								as={Link}
								to={nl.to}
								key={nl.to}
								className={classNames({
									active: location.pathname === nl.to,
								})}
							>
								<LinkInner>{nl.label}</LinkInner>
							</ActionLink>
						))}
						<Search searchScope="site">
							<ActionLink as="button" className="is-icon separator">
								<span className="mobile-only icon-after">Search site</span>
								<FontAwesomeIcon icon={faSearch} />
							</ActionLink>
						</Search>
						{secondaryNavLinks.map(nl => (
							<ActionLink
								as={Link}
								to={nl.to}
								key={nl.to}
								className={classNames({
									active: location.pathname === nl.to,
								})}
							>
								<LinkInner>{nl.label}</LinkInner>
							</ActionLink>
						))}
						<ConditionalContainer className="mobile-only">
							{actions}
						</ConditionalContainer>
					</Navigation>
					<NavigationOverlay className={classNames({ open: navOpen })} />
					<PriorityActions>
						<ActionLink
							as={Link}
							className={classNames(
								{ active: location.pathname === '/pricing/' },
								'highlighted'
							)}
							to="/pricing/"
						>
							<span className="icon-after">Sign up</span>
							<FontAwesomeIcon icon={faArrowRight} className="small-icon" />
						</ActionLink>
						<ConditionalContainer className="desktop-only">
							{actions}
						</ConditionalContainer>
						<ActionLink
							as="button"
							className="is-icon opens-menu"
							onClick={(e: any) => {
								e.preventDefault();
								e.stopPropagation();
								setNavOpen(v => !v);
							}}
						>
							<LinkInner>
								<MenuSvg aria-label="Open navigation menu" />
							</LinkInner>
						</ActionLink>
					</PriorityActions>
				</HeaderInner>
			</Constrain>
		</HeaderContainer>
	);
}
