import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
	Dispatch,
	SetStateAction,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';
import { globalHistory } from '@reach/router';
import {
	buttonResetCss,
	gv,
	ns,
	bp,
	getMultipleTransitionsWithWillChange,
	screenReaderText,
} from '../../utils';
import Button from '../Button';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons/faTabletAlt';
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop';
import classNames from 'classnames';

ReactModal.setAppElement('#___gatsby');

const VideoMain = styled.div`
	margin: 0 auto;
	max-width: 960px;
	flex: 0 0 100%;
`;

const VideoWrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	background-color: ${gv('appBackgroundColor')};
	box-shadow: ${props => props.theme.boxShadow4dp};
	overflow: hidden;
	flex: 0 0 100%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const IFrameContainer = styled.div`
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	position: relative;
	height: 100%;
	iframe {
		display: block;
		overflow-y: auto;
		position: absolute;
		height: 100%;
		width: 100%;
		border: 0 none;
		outline: none;
	}
`;

const ResponsiveMenuContainer = styled.div`
	flex-flow: row nowrap;
	justify-content: flex-end;
	padding: 0 ${props => ns(props.theme.gutter)};
	height: ${props => ns(props.theme.controlHeightBase * 1.25)};
	align-items: center;
	> * {
		margin: 0 1px;
		flex: 0 0 auto;
	}
`;

const Header = styled.h3`
	height: ${props => ns(props.theme.controlHeightBase * 1.25)};
	line-height: ${props => ns(props.theme.controlHeightBase * 1.25)};
	margin: 0;
	padding: 0 ${props => ns(props.theme.gutter)};
	background: ${gv('primaryColor')};
	color: ${gv('primaryBgText')};
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.large2)};
	font-weight: bold;
	flex: 0 0 auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&.has-responsive-menu {
		${ResponsiveMenuContainer} {
			display: none;
		}
		@media ${props => bp(props, 'desktop')} {
			padding-right: 160px;
			position: relative;
			${ResponsiveMenuContainer} {
				display: flex;
				width: 160px;
				right: 0;
				top: 0;
				position: absolute;
			}
		}
	}
`;

const ResponsiveContent = styled.div`
	width: 100%;
	margin: 0 auto;
	@media ${props => bp(props, 'desktop')} {
		&.mobile {
			max-width: 400px;
		}
		&.tablet {
			max-width: 500px;
		}
	}
`;

const Content = styled.main`
	align-self: stretch;
	flex: 1 0 auto;
	overflow-y: auto;
	height: calc(100% - ${props => ns(props.theme.controlHeightBase * 2.75)});
`;

const Footer = styled.footer`
	height: ${props => ns(props.theme.controlHeightBase * 1.5)};
	background: ${gv('backgroundShade')};
	flex: 0 0 auto;
	margin: auto 0 0 0;
	padding: 0 ${props => ns(props.theme.gutter)};
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	> * {
		flex: 0 0 auto;
		margin: ${props => ns(props.theme.gutter * 0.25)};
		&:first-child {
			margin-left: 0 !important;
		}
		&:last-child {
			margin-left: auto !important;
			margin-right: 0 !important;
		}
	}
`;

const GeneralContainer = styled.div<{ slim?: boolean; attentive?: boolean }>`
	padding: ${props => (props.slim ? '0' : ns(props.theme.gutter))};
	height: ${props => (props.slim ? '100%' : 'auto')};
	${props =>
		props.attentive
			? css`
					border-top: 5px solid ${gv('secondaryAccentColor')};
					background-color: ${gv('appBackgroundColor')};
					box-shadow: ${props => props.theme.boxShadow4dp};
			  `
			: null};
`;

const AttentiveContainer = styled.div`
	padding: ${props => ns(props.theme.gutter)};
	overflow: auto;
	height: 100%;
	max-height: calc(100vh - 80px);
`;

const CloseButton = styled.button`
	${buttonResetCss};
	position: fixed;
	height: ${props => ns(props.theme.gutter * 2)};
	width: ${props => ns(props.theme.gutter * 2)};
	font-size: ${props => ns(props.theme.gutter * 1.25)};
	@media ${props => bp(props, 'desktop')} {
		height: ${props => ns(props.theme.gutter * 3)};
		width: ${props => ns(props.theme.gutter * 3)};
		font-size: ${props => ns(props.theme.gutter * 2)};
	}
	background-color: transparent;
	color: rgba(255, 255, 255, 0.75);
	top: 0;
	right: 0;
	z-index: 9999999;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: ${props =>
		getMultipleTransitionsWithWillChange(['color'], props.theme.transition)};

	&:hover,
	&:active {
		color: rgba(255, 255, 255, 1);
	}
	span.text {
		${screenReaderText};
	}
`;

function ResponsiveWidthControl(props: {
	responsiveSize: 'mobile' | 'tablet' | 'desktop';
	setResponsiveSize: React.Dispatch<
		React.SetStateAction<'mobile' | 'tablet' | 'desktop'>
	>;
}) {
	const { responsiveSize, setResponsiveSize } = props;
	return (
		<ResponsiveMenuContainer>
			<Button
				as="button"
				className={classNames({ active: responsiveSize === 'mobile' })}
				type="transparent"
				size="icon"
				onClick={() => {
					setResponsiveSize('mobile');
				}}
			>
				<FontAwesomeIcon icon={faMobileAlt} />
			</Button>
			<Button
				as="button"
				className={classNames({ active: responsiveSize === 'tablet' })}
				type="transparent"
				size="icon"
				onClick={() => {
					setResponsiveSize('tablet');
				}}
			>
				<FontAwesomeIcon icon={faTabletAlt} />
			</Button>
			<Button
				as="button"
				className={classNames({ active: responsiveSize === 'desktop' })}
				type="transparent"
				size="icon"
				onClick={() => {
					setResponsiveSize('desktop');
				}}
			>
				<FontAwesomeIcon icon={faDesktop} />
			</Button>
		</ResponsiveMenuContainer>
	);
}

export type ModalProps = {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	maxWidth?: string;
	maxHeight?: string;
	onAfterClose?: () => void;
	onAfterOpen?: () => void;
} & (
	| {
			type: 'youtube';
			youtubeVideoId: string;
	  }
	| {
			type: 'regular';
			children: ReactNode;
	  }
	| {
			type: 'iframe';
			iframeLink?: string;
			title?: string;
	  }
	| {
			type: 'lightbox';
			children: ReactNode;
			header: ReactNode;
			footer: ReactNode;
			contentKey?: string;
			showResponsive?: boolean;
	  }
	| {
			type: 'attentive';
			children: ReactNode;
	  }
);
export default function Modal(props: ModalProps) {
	const { isOpen, setIsOpen, maxWidth, maxHeight, onAfterClose, onAfterOpen } =
		props;
	// always close modal when navigating away
	useEffect(() => {
		return globalHistory.listen(({ action }) => {
			if (action === 'PUSH') {
				setIsOpen(false);
			}
		});
	}, [setIsOpen]);
	const contentStyle: React.CSSProperties = { maxWidth, maxHeight };
	const [responsiveSize, setResponsiveSize] = useState<
		'mobile' | 'tablet' | 'desktop'
	>('desktop');

	return (
		<ReactModal
			isOpen={isOpen}
			overlayClassName="wpeform-site-modal-overlay"
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			shouldFocusAfterRender
			shouldReturnFocusAfterClose={false}
			onRequestClose={() => {
				setIsOpen(false);
			}}
			closeTimeoutMS={300}
			className={`wpeform-site-modal wpeform-site-modal--${props.type}`}
			style={{ content: contentStyle }}
			onAfterClose={onAfterClose}
			onAfterOpen={onAfterOpen}
		>
			<CloseButton
				onClick={e => {
					e.preventDefault();
					setIsOpen(false);
				}}
				aria-label="Close Modal"
				className="ReactModal__Content__CloseButton"
			>
				<FontAwesomeIcon icon={faTimes} />
				<span className="text">CLOSE</span>
			</CloseButton>
			{props.type === 'youtube' ? (
				<VideoMain>
					<VideoWrapper>
						<iframe
							title="Video Preview"
							width="560"
							height="315"
							src={`https://www.youtube.com/embed/${props.youtubeVideoId}`}
							frameBorder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					</VideoWrapper>
				</VideoMain>
			) : props.type === 'iframe' ? (
				props.iframeLink ? (
					<IFrameContainer>
						<iframe
							allowTransparency
							src={props.iframeLink}
							title={props.title}
							key={props.iframeLink}
						/>
					</IFrameContainer>
				) : null
			) : props.type === 'lightbox' ? (
				<GeneralContainer slim>
					<Header
						className={classNames({
							'has-responsive-menu': props.showResponsive,
						})}
						as={props.showResponsive ? 'div' : 'h3'}
					>
						{props.header}
						{props.showResponsive ? (
							<ResponsiveWidthControl
								responsiveSize={responsiveSize}
								setResponsiveSize={setResponsiveSize}
							/>
						) : null}
					</Header>
					<Content
						key={props.contentKey}
						className="ReactModal__LightBox__Content"
					>
						{props.showResponsive ? (
							<ResponsiveContent className={responsiveSize}>
								{props.children}
							</ResponsiveContent>
						) : (
							props.children
						)}
					</Content>
					<Footer>{props.footer}</Footer>
				</GeneralContainer>
			) : props.type === 'attentive' ? (
				<GeneralContainer slim attentive>
					<AttentiveContainer>{props.children}</AttentiveContainer>
				</GeneralContainer>
			) : (
				<GeneralContainer>{props.children}</GeneralContainer>
			)}
		</ReactModal>
	);
}
