import React, { lazy } from 'react';
import { RenderSsr } from '@wpeform/react/render-ssr';
import type { WPEFormProps } from '@wpeform/react';
import { useIsDarkTheme } from '../../theme/Provider';

const LazyForm = lazy(
	() =>
		import(
			/* webpackChunkName: "wpeform", webpackPrefetch: true */
			'@wpeform/react/dist/sdk/lazy-imports'
		)
);

export type EmbedFormProps = Omit<WPEFormProps, 'overrideDarkMode'>;
export default function EmbedForm(props: EmbedFormProps) {
	const darkMode = useIsDarkTheme();
	const { formId, controls, panels, themeStyle, inShadow = false } = props;
	return (
		<RenderSsr
			controls={controls}
			inShadow={inShadow}
			panels={panels}
			themeStyle={themeStyle}
		>
			<LazyForm
				id={formId.toString()}
				controls={controls}
				inShadow={inShadow}
				mode="newsubmission"
				panels={panels}
				ssr={false}
				themeStyle={themeStyle}
				overrideDarkMode={darkMode === null ? undefined : darkMode}
				increaseViewCount
			/>
		</RenderSsr>
	);
}
