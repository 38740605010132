import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons/faLightbulb';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { gv, ns } from '../../utils';
import classNames from 'classnames';

const TipIcon = styled.div`
	position: absolute;
	height: ${props => ns(props.theme.controlHeightBase)};
	width: ${props => ns(props.theme.controlHeightBase)};
	top: ${props => ns(props.theme.controlHeightBase * -0.35)};
	left: ${props => ns(props.theme.controlHeightBase * -0.35)};
	/* left: 50%;
	transform: translateX(-50%); */
	background-color: ${gv('appBackgroundColor')};
	font-size: ${props => ns(props.theme.fz.small1)};
	padding: 4px;
	border-radius: 100%;
	.border {
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: ${props => ns(props.theme.controlHeightBase - 8)};
		width: ${props => ns(props.theme.controlHeightBase - 8)};
		border: 2px solid;
	}
`;

const Container = styled.div`
	&&&& {
		margin: ${props => ns(props.theme.gutter * 2)} 0;
		background-color: ${gv('backgroundShade')};
		box-shadow: ${props => props.theme.boxShadowBase};
		border-radius: ${props => ns(props.theme.borderRadiusBase)};
		border-left: 3px solid;
		padding: ${props => ns(props.theme.gutter)};
		p,
		div.para,
		ul,
		ol {
			margin-top: 0.5em;
		}
		ul,
		ol {
			margin-bottom: 0.5em;
		}
		li {
			padding-top: ${props => ns(props.theme.gutter * 0.25)};
			padding-bottom: ${props => ns(props.theme.gutter * 0.25)};
		}
		> *:last-child {
			margin-bottom: 0;
		}
		${TipIcon} + * {
			margin-top: 0;
		}
		position: relative;
		&.hint {
			border-color: ${gv('secondaryAccentColor')};
			${TipIcon} {
				border-color: ${gv('secondaryAccentColor')};
				color: ${gv('secondaryAccentColor')};
			}
		}
		&.info {
			border-color: ${gv('primaryColor')};
			${TipIcon} {
				border-color: ${gv('primaryColor')};
				color: ${gv('primaryColor')};
			}
		}
		&.warn {
			border-color: ${gv('errorColor')};
			${TipIcon} {
				border-color: ${gv('errorColor')};
				color: ${gv('errorColor')};
			}
		}
	}
`;

type TipBoxTypes = 'hint' | 'info' | 'warn';
const typeToIconMap: { [x in TipBoxTypes]: typeof faLightbulb } = {
	hint: faLightbulb,
	info: faInfo,
	warn: faExclamation,
};

export type TipBoxProps = {
	type?: TipBoxTypes;
};
export default function TipBox(props: PropsWithChildren<TipBoxProps>) {
	const { type = 'hint', children } = props;
	return (
		<Container className={classNames('tipbox', type)}>
			<TipIcon>
				<span className="border">
					<FontAwesomeIcon icon={typeToIconMap[type]} aria-label={type} />
				</span>
			</TipIcon>
			{children}
		</Container>
	);
}
