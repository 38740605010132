import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import classNames from 'classnames';
import {
	buttonResetCss,
	controlSkewCss,
	controlSkewReverseCss,
	getMultipleTransitionsWithWillChange,
	gv,
	ns,
} from '../../utils';

export const SkewReversal = styled.span`
	${controlSkewReverseCss}
`;
export const StyledLink = styled.a`
	&&&& {
		${buttonResetCss};
		line-height: 1;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		font-family: ${props => props.theme.fontFamilyHeading};
		font-weight: bold;
		transition: ${props =>
			getMultipleTransitionsWithWillChange(
				['box-shadow'],
				props.theme.transitionControl
			)};
		margin: ${props => ns(props.theme.gutter / 2)};
		${controlSkewCss};

		${SkewReversal} {
			opacity: 1;
			transition: ${props =>
				getMultipleTransitionsWithWillChange(
					['opacity'],
					props.theme.transitionControl
				)};
		}
		--bg-color: ${gv('backgroundControl')};
		--color: ${gv('textColor')};
		--box-shadow-color: ${gv('controlShadowDefault')};
		--box-shadow-offset: 6px;
		--box-shadow-offset-hover: 2px;
		--box-shadow-offset-active: 0px;

		background-color: var(--bg-color);
		color: var(--color);
		box-shadow: var(--box-shadow-offset) var(--box-shadow-offset) 0 0
			var(--box-shadow-color);

		&:hover {
			color: var(--color);
			box-shadow: var(--box-shadow-offset-hover) var(--box-shadow-offset-hover)
				0 0 var(--box-shadow-color);
			${SkewReversal} {
				opacity: 1;
			}
		}
		&:active,
		&:focus {
			color: var(--color);
			box-shadow: var(--box-shadow-offset-active)
				var(--box-shadow-offset-active) 0 0 var(--box-shadow-color);
			${SkewReversal} {
				opacity: 1;
			}
			transition: none;
		}

		// sizes
		padding: 0 ${props => ns(props.theme.gutter)};
		height: ${props => ns(props.theme.controlHeightBase * 0.75)};
		font-size: ${props => ns(props.theme.fz.small1)};
		&.regular {
			padding: 0 ${props => ns(props.theme.gutter * 1.5)};
			height: ${props => ns(props.theme.controlHeightBase * 1)};
			font-size: ${props => ns(props.theme.fz.base)};
			--box-shadow-offset: 8px;
			--box-shadow-offset-hover: 4px;
			--box-shadow-offset-active: 2px;
		}
		&.large {
			padding: 0 ${props => ns(props.theme.gutter * 2)};
			height: ${props => ns(props.theme.controlHeightBase * 1.25)};
			font-size: ${props => ns(props.theme.fz.large1)};
			--box-shadow-offset: 10px;
			--box-shadow-offset-hover: 6px;
			--box-shadow-offset-active: 4px;
		}

		// Styles
		&.inverted {
			--bg-color: ${gv('primaryBgText')};
			--color: ${gv('primaryDarkColor')};
			--box-shadow-color: ${gv('controlShadowInverted')};
		}
		&.primary {
			--bg-color: ${gv('primaryColor')};
			--color: ${gv('primaryBgText')};
			--box-shadow-color: ${gv('controlShadowPrimary')};
		}
		&.ghost {
			--bg-color: rgba(0, 0, 0, 0);
			--color: inherit;
			--box-shadow-color: ${gv('controlShadowGhost')};
		}
		&.accent {
			--bg-color: ${gv('accentColor')};
			--color: ${gv('primaryBgText')};
			--box-shadow-color: ${gv('controlShadowAccent')};
		}
		&.accent-secondary {
			--bg-color: ${gv('secondaryAccentColor')};
			--color: ${gv('primaryBgText')};
			--box-shadow-color: ${gv('controlShadowAccentSecondary')};
		}

		// fontawesome optimization
		.svg-inline--fa {
			font-size: 0.8em;
			position: relative;
			bottom: 0.05em;
			margin: 0 0.5em 0 0;
			&:last-child {
				margin: 0 0 0 0.5em;
			}
			&:only-child {
				margin: 0;
			}
		}

		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
			box-shadow: none;
		}

		// styles
		&.rounded {
			transform: none;
			border-radius: ${props => ns(props.theme.borderRadiusBase)};
			box-shadow: ${props => props.theme.boxShadowNoneFocus};
			&:hover,
			&:active,
			&:focus {
				box-shadow: ${props => props.theme.boxShadow4dp};
			}
			${SkewReversal} {
				transform: none;
			}

			&.ghost {
				box-shadow: none;
				&:hover,
				&:active,
				&:focus {
					box-shadow: none;
					background-color: rgba(0, 0, 0, 0.1);
				}
			}
		}
	}
`;

export type CtaProps = {
	type?:
		| 'default'
		| 'inverted'
		| 'primary'
		| 'ghost'
		| 'accent'
		| 'accent-secondary';
	size?: 'large' | 'regular' | 'small';
	to: string | (() => void);
	disabled?: boolean;
	target?: string;
	rel?: string;
	design?: 'skewed' | 'rounded';
	title?: string;
};
export default function Cta(props: PropsWithChildren<CtaProps>) {
	const {
		type = 'default',
		size = 'regular',
		children,
		to,
		disabled,
		target,
		rel,
		design = 'skewed',
		title,
	} = props;
	const className = classNames('cta', {
		[type]: true,
		[size]: true,
		[design]: true,
	});
	return typeof to === 'function' ? (
		<StyledLink
			as="button"
			onClick={(e: any) => {
				e.preventDefault();
				to();
			}}
			className={className}
			disabled={disabled}
			title={title}
		>
			<SkewReversal>{children}</SkewReversal>
		</StyledLink>
	) : to.startsWith('http://') ||
	  to.startsWith('https://') ||
	  to.startsWith('//') ? (
		<StyledLink
			href={to}
			title={title}
			className={className}
			target={target}
			rel={rel}
		>
			<SkewReversal>{children}</SkewReversal>
		</StyledLink>
	) : (
		<StyledLink title={title} as={Link} to={to} className={className}>
			<SkewReversal>{children}</SkewReversal>
		</StyledLink>
	);
}

export const CtaGroup = styled.nav`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row wrap;

	// sizes
	// prettier-ignore
	margin: ${props => ns(props.theme.gutter * 0.75)} ${props =>
		ns(props.theme.gutter / -2)} ${props =>
		ns(props.theme.gutter * 0.75)} ${props => ns(props.theme.gutter / -2)};
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.tablet)}) {
		margin-top: ${props => ns(props.theme.gutter * 1)};
		margin-bottom: ${props => ns(props.theme.gutter)};
	}
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.desktop)}) {
		margin-top: ${props => ns(props.theme.gutter * 1.5)};
		margin-bottom: ${props => ns(props.theme.gutter)};
	}

	&.regular {
		margin-top: ${props => ns(props.theme.gutter * 1)};
		@media screen and (min-width: ${props =>
				ns(props.theme.breakpoints.tablet)}) {
			margin-top: ${props => ns(props.theme.gutter * 1.5)};
		}
		@media screen and (min-width: ${props =>
				ns(props.theme.breakpoints.desktop)}) {
			margin-top: ${props => ns(props.theme.gutter * 2)};
		}
	}

	&.large {
		margin-top: ${props => ns(props.theme.gutter * 1.5)};
		@media screen and (min-width: ${props =>
				ns(props.theme.breakpoints.tablet)}) {
			margin-top: ${props => ns(props.theme.gutter * 2)};
		}
		@media screen and (min-width: ${props =>
				ns(props.theme.breakpoints.desktop)}) {
			margin-top: ${props => ns(props.theme.gutter * 3)};
		}
	}

	// alignments
	&.center-left-desktop {
		@media screen and (min-width: ${props =>
				ns(props.theme.breakpoints.desktop)}) {
			justify-content: flex-start;
		}
	}
	&.justify {
		justify-content: stretch;
		> * {
			flex-grow: 1;
		}
	}
	&.left {
		justify-content: flex-start;
	}

	// Gutter
	&.no-gutter-bottom {
		margin-bottom: 0;
	}
	&.no-gutter-top {
		margin-top: 0;
	}
	&.has-gutter-horizontal {
		padding-left: ${props => ns(props.theme.gutter)};
		padding-right: ${props => ns(props.theme.gutter)};
	}
	&.has-gutter-vertical {
		margin: 0;
		padding-top: ${props => ns(props.theme.gutter)};
		padding-bottom: ${props => ns(props.theme.gutter)};
	}
`;

const GroupHeading = styled.h4`
	flex: 0 0 100%;
	width: 100%;
	color: ${gv('textColorSecondary')};
	font-size: ${props => ns(props.theme.fz.small1)};
	font-weight: bold;
	font-family: ${props => props.theme.fontFamilyHeading};
	margin: 0 0 ${props => ns(props.theme.gutter / 2)}
		${props => ns(props.theme.gutter / 2)};
`;

export type GroupProps = {
	size?: 'small' | 'regular' | 'large';
	align?: 'center-left-desktop' | 'center' | 'justify' | 'left';
	noGutterBottom?: boolean;
	noGutterTop?: boolean;
	hasGutterHorizontal?: boolean;
	hasGutterVertical?: boolean;
};
function Group(props: PropsWithChildren<GroupProps>) {
	const {
		size = 'regular',
		align = 'center',
		children,
		noGutterBottom = false,
		noGutterTop = false,
		hasGutterHorizontal = false,
		hasGutterVertical = false,
	} = props;
	return (
		<CtaGroup
			className={classNames('cta-group', {
				[align]: true,
				[size]: true,
				'no-gutter-bottom': noGutterBottom,
				'no-gutter-top': noGutterTop,
				'has-gutter-horizontal': hasGutterHorizontal,
				'has-gutter-vertical': hasGutterVertical,
			})}
		>
			{children}
		</CtaGroup>
	);
}
Cta.Group = Group;
Cta.GroupHeading = GroupHeading;
