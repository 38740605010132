import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {
	buttonResetCss,
	getCookie,
	getMultipleTransitionsWithWillChange,
	gv,
	ns,
	setCookie,
} from '../../utils';

const Button = styled.button`
	${buttonResetCss};
	width: 100px;
	height: 32px;
	flex: 0 0 100px;
	background-color: #0967d2;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	box-shadow: ${props => props.theme.boxShadowNoneFocus};
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['box-shadow', 'background-color'],
			props.theme.transitionControl
		)};
	&:hover {
		background-color: #2186eb;
	}
	&:active,
	&:focus {
		box-shadow: ${gv('boxShadowControlFocus')};
		background-color: #0552b5;
	}
`;
const Text = styled.p`
	margin: 0 0 ${props => ns(props.theme.gutter)} 0;
	line-height: 1.3;
`;
const Content = styled.aside``;
const Container = styled.div`
	position: fixed;
	bottom: ${props => ns(props.theme.gutter)};
	left: ${props => ns(props.theme.gutter)};
	width: 400px;
	max-width: calc(100% - ${props => ns(props.theme.gutter * 2)});
	height: auto;
	background-color: rgba(50, 63, 75, 0.9);
	backdrop-filter: blur(10px);
	color: #f5f7fa;
	padding: ${props => ns(props.theme.gutter)};
	box-shadow: ${props => props.theme.boxShadow3dp};
	z-index: 999999;
	opacity: 1;
	&.hidden {
		opacity: 0;
	}
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['opacity'],
			props.theme.transitionControl
		)};
	a {
		color: #47a3f3;
		text-decoration: underline;
		&:hover {
			color: #47a3f3;
			text-decoration: none;
		}
	}
`;

export default function CookieConscent() {
	const [isVisible, setIsVisible] = useState<boolean | null>(null);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const hasConscent = getCookie('wpeform-cookie-conscent');
			if (hasConscent === 'yes') {
				setIsVisible(false);
			} else {
				setIsVisible(true);
			}
		}
	}, []);

	return isVisible !== false ? (
		<Container className={classNames({ hidden: isVisible === null })}>
			<Content>
				<Text>
					We use cookies to analyze traffic and sale. To find out more, please
					see our{' '}
					<Link to="/privacy/#5-cookies-to-enrich-and-perfect-your-online-experience">
						Privacy policy
					</Link>
					. By continuing to browse our website, you agree to our use of
					cookies.
				</Text>
				<Button
					onClick={e => {
						e.preventDefault();
						setIsVisible(false);
						if (typeof window !== 'undefined') {
							setCookie('wpeform-cookie-conscent', 'yes', 365);
						}
					}}
				>
					Okay
				</Button>
			</Content>
		</Container>
	) : null;
}
