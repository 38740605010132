import { DefaultTheme } from 'styled-components';
import { transparentize, darken, lighten } from 'polished';
import { ns } from '../utils';
import { palette } from './palette';
import { ColorVariablesType } from './types';

// general colors
export const darkColor: ColorVariablesType = {
	primaryLightColor: palette.primary['400'],
	primaryColor: palette.primary['500'],
	primaryDarkColor: palette.primary['700'],
	primaryBackgroundColor: palette.primary['800'],
	primaryBgText: '#ffffff',
	accentColor: palette.supporting.cyan['700'],
	secondaryAccentColor: palette.supporting.yellow['600'],
	greyLightColor: palette.neutrals['400'],
	greyColor: palette.neutrals['500'],
	greyDarkColor: palette.neutrals['700'],
	greyBgText: '#ffffff',
	backgroundShade: palette.neutrals['800'], // custom modification on hsl value hsl(216, 33%, 94%)
	backgroundControl: palette.neutrals['700'],
	backgroundHover: darken(0.05, palette.neutrals['800']), // custom modification of hsl value hsl(214, 15%, 95%)
	appBackgroundColor: palette.neutrals['900'],
	linkColor: palette.primary['300'],
	linkHoverColor: lighten(0.1, palette.primary['300']),
	successColor: palette.supporting.green['300'],
	warningColor: palette.supporting.yellow['300'],
	warningBackgroundColor: palette.supporting.yellow['900'],
	errorColor: palette.supporting.red['300'],
	errorBackgroundColor: palette.supporting.red['900'],
	successBackgroundColor: palette.supporting.green['900'],
	// typography colors
	headingColor: palette.neutrals['200'],
	textColor: palette.neutrals['100'],
	textColorSecondary: palette.neutrals['300'],
	darkTextColor: palette.neutrals['100'],
	lightTextColor: palette.neutrals['700'],
	borderColorBase: palette.neutrals['500'],
	borderColorSplit: palette.neutrals['600'],
	borderColorLight: palette.neutrals['700'],
	backgroundSelected: palette.neutrals['700'],
	borderColorError: palette.supporting.red['600'],
	disabledColor: palette.neutrals['600'],
	disabledBackgroundColor: lighten(0.04, palette.neutrals['900']), // hsl(216, 33%, 98%)
	boxShadowInset:
		'inset -1px 0 3px 0 rgba(0, 0, 0, 0.01), inset -2px 2px 6px 0 rgba(0,0,0,0.06)',
	boxShadowFocus: `0 0 0 2px ${transparentize(0.5, palette.primary['500'])}`,
	// 12 is the small2 font size
	boxShadowControlFocus: `0 0 0 ${ns(12 / 4)} ${transparentize(
		0.8,
		palette.primary['500']
	)}`,
	boxShadowControlFocusError: `0 0 0 ${ns(12 / 4)} ${transparentize(
		0.8,
		palette.supporting.red['500']
	)}`,
	boxShadowControlFocusSuccess: `0 0 0 ${ns(12 / 4)} ${transparentize(
		0.8,
		palette.supporting.green['500']
	)}`,
	// buttons and controls
	controlShadowDefault: `${transparentize(0.5, palette.neutrals['300'])}`,
	controlShadowInverted: `rgba(255, 255, 255, 0.5)`,
	controlShadowPrimary: `${transparentize(0.5, palette.primary['600'])}`,
	controlShadowGhost: `rgba(255, 255, 255, 0.2)`,
	controlShadowAccent: `${transparentize(0.5, palette.supporting.cyan['600'])}`,
	controlShadowAccentSecondary: `${transparentize(
		0.5,
		palette.supporting.yellow['600']
	)}`,
	blobColor: '#293642',
	proseColor: palette.neutrals['200'],
	chBgColor: palette.neutrals['800'],
	chBgHighlight: darken(0.03, palette.neutrals['800']),
	chNumBgHighlight: darken(0.05, palette.neutrals['800']),
	chColor: palette.neutrals['200'],
	chSelectionBg: palette.neutrals['700'],
	chComment: palette.neutrals['500'],
	chPunctuation: palette.neutrals['400'],
	chRed: palette.supporting.red['300'],
	chYellow: palette.supporting.yellow['300'],
	chGreen: palette.supporting.green['300'],
	chBlue: palette.supporting.cyan['300'],
	chPurple: palette.supporting.orange['300'],
	chBlueTwo: palette.supporting.cyan['400'],
	chLineNum: palette.neutrals['500'],
	chLineNumBg: lighten(0.02, palette.neutrals['800']),
};

export const darkTheme: DefaultTheme = {
	mode: 'dark',
	// typography & sizes
	fontFamilyBody: `--apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
	fontFamilyHeading: `'Lato', --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
	fontFamilyMono: `dm, "Dank Mono", "Operator Mono", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace`,
	fontFamilyLogo: `'Nunito', --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
	fz: {
		small2: 12,
		small1: 14,
		base: 16,
		large1: 18,
		large2: 20,
		large3: 24,
		large4: 30,
		large5: 36,
		large6: 48,
		large7: 60,
		large8: 72,
	},
	boldHeading: true,
	italicHeading: false,
	// control appearance
	borderRadiusBase: 4,
	boxShadowBase: '0 2px 8px rgba(0, 0, 0, .15)',
	boxShadowElevationOne:
		'0 1px 3px rgba(0, 0, 0, 0.01), 0 1px 2px rgba(0, 0, 0, 0.06)',
	boxShadowHover: '0 3px 6px rgba(0,0,0,0.01), 0 3px 6px rgba(0,0,0,0.06)',
	boxShadowBottom:
		'0 2px 3px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.06)',
	boxShadowTop:
		'0 -1px 2px rgba(0, 0, 0, 0.01), 0 -2px 6px rgba(0, 0, 0, 0.06)',
	boxShadowRight: '1px 0 3px 0 rgba(0,0,0,0.01), 1px 0 2px 0 rgba(0,0,0,0.06)',
	boxShadowNoneFocus: `0 0 0 0 transparent`,
	boxShadow1dp: `0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)`,
	boxShadow2dp: `0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2)`,
	boxShadow3dp: `0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)`,
	boxShadow4dp: `0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)`,
	boxShadow5dp: `0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2)`,
	boxShadow6dp: `0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)`,
	// control sizing
	gutter: 20,
	controlHeightBase: 40,
	transition: '300ms ease-out',
	transitionControl: '200ms ease-out',
	transitionBeizer: '300ms cubic-bezier(0.755, 0.05, 0.855, 0.06)',
	breakpoints: {
		tablet: 768,
		desktop: 1024,
		desktopHd: 1200,
	},
};
