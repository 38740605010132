import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { gv, ns, proseCss } from '../../utils';
import animationData from './animationData.json';

const Animation = styled.div`
	width: 256px;
	margin: 0 auto ${props => ns(props.theme.gutter * 2)};
	max-width: 100%;
`;
const Text = styled.div`
	${proseCss};
	text-align: center;
	color: ${gv('textColorSecondary')};
`;

const Container = styled.div`
	max-width: 600px;
	margin: 0 auto;
	padding: ${props => ns(props.theme.gutter)};
`;

export default function PageLoader(props: PropsWithChildren<{}>) {
	const { children } = props;
	return (
		<Container>
			<Animation>
				<Lottie options={{ animationData, loop: true, autoplay: true }} />
			</Animation>
			<Text>{children}</Text>
		</Container>
	);
}
