exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-docs-video-tutorials-tsx": () => import("./../../../src/pages/docs/video-tutorials.tsx" /* webpackChunkName: "component---src-pages-docs-video-tutorials-tsx" */),
  "component---src-pages-examples-tsx": () => import("./../../../src/pages/examples.tsx" /* webpackChunkName: "component---src-pages-examples-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-templates-blog-author-archive-template-tsx": () => import("./../../../src/templates/blogAuthorArchiveTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-author-archive-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-blog-tag-archive-template-tsx": () => import("./../../../src/templates/blogTagArchiveTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-tag-archive-template-tsx" */),
  "component---src-templates-doc-root-template-tsx": () => import("./../../../src/templates/docRootTemplate.tsx" /* webpackChunkName: "component---src-templates-doc-root-template-tsx" */),
  "component---src-templates-doc-template-tsx": () => import("./../../../src/templates/docTemplate.tsx" /* webpackChunkName: "component---src-templates-doc-template-tsx" */),
  "component---src-templates-feature-template-tsx": () => import("./../../../src/templates/featureTemplate.tsx" /* webpackChunkName: "component---src-templates-feature-template-tsx" */),
  "component---src-templates-root-template-tsx": () => import("./../../../src/templates/rootTemplate.tsx" /* webpackChunkName: "component---src-templates-root-template-tsx" */),
  "component---src-templates-tools-template-tsx": () => import("./../../../src/templates/toolsTemplate.tsx" /* webpackChunkName: "component---src-templates-tools-template-tsx" */)
}

