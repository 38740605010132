import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { gv } from '../../utils';

const LogoTextWp = styled.span`
	color: ${gv('linkColor')};
`;
const LogoTextEform = styled.span`
	color: ${gv('textColor')};
`;
const LogoTextContainer = styled.span`
	font-family: ${props => props.theme.fontFamilyLogo};
	font-weight: 800;
	line-height: 1;
	text-rendering: geometricPrecision;
`;

function Text(props: { hasIo?: boolean }) {
	const { hasIo = false } = props;
	return (
		<LogoTextContainer>
			<LogoTextWp>wp</LogoTextWp>
			<LogoTextEform>eform{hasIo ? '.io' : null}</LogoTextEform>
		</LogoTextContainer>
	);
}

export type LogoProps = {
	className?: string;
};
export default function Logo(props: LogoProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width={256}
			height={256}
			viewBox="0 0 256 256"
			className={classNames('wpeform-logo', props.className)}
		>
			<defs>
				<path
					id="prefix__a"
					d="M33.684 130.051a8.425 8.425 0 00-8.42-8.42H8.42c-4.648 0-8.421 3.772-8.421 8.42v23.58c0 4.647 3.773 8.42 8.421 8.42h16.842c4.648 0 8.421-3.773 8.421-8.42v-23.58zm33.684-55.795c0-5.577-4.528-10.105-10.105-10.105H10.105C4.528 64.15 0 68.679 0 74.256v20.211c0 5.578 4.528 10.106 10.105 10.106h47.158c5.577 0 10.105-4.528 10.105-10.106v-20.21zm67.37-64.15c0-5.579-4.53-10.106-10.106-10.106H10.105C4.528 0 0 4.527 0 10.105v20.21c0 5.578 4.528 10.106 10.105 10.106h114.527c5.577 0 10.105-4.528 10.105-10.105v-20.21z"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<path
					className="wpeform-logo__fill-bg"
					fillRule="nonzero"
					d="M237.137 119.076l-14.166 13.968c-.123.121-.251.236-.384.344a9.92 9.92 0 01-6.767 2.652c-5.507 0-9.978-4.471-9.978-9.98l.014-.537-76.429 76.185a5.773 5.773 0 01-2.228 1.381l-23.187 7.835a9.968 9.968 0 01-12.691-12.462l7.486-23.565c.28-.884.77-1.687 1.426-2.341l76.348-76.103 49.026-48.868a20.69 20.69 0 0111.53-6.564v-8.684C237.137 14.49 222.647 0 204.8 0H32.337C14.49 0 0 14.49 0 32.337v191.326C0 241.51 14.49 256 32.337 256H204.8c17.847 0 32.337-14.49 32.337-32.337V119.076z"
				/>
				<path
					className="wpeform-logo__fill-bg"
					fillRule="nonzero"
					d="M175.45 106.338l-71.14 70.913-7.486 23.564a4.194 4.194 0 005.34 5.243l23.187-7.834 71.14-70.913-21.04-20.973zm37.71 23.583a4.2 4.2 0 01-1.544-3.256 4.2 4.2 0 011.57-3.278l17.897-17.646c5.554-5.475 7.968-12.928 7.233-20.12l-36.618 36.5-21.04-20.973 49.163-49.006a14.908 14.908 0 0111.258-5.116c8.235 0 14.921 6.664 14.921 14.873 0 4.518-2.025 8.568-5.221 11.298l-5.345 5.328c3.452 11.38.63 24.244-8.433 33.181l-18.085 17.831-.013-.014a4.193 4.193 0 01-3.083 1.348c-.98 0-1.882-.337-2.598-.9l-.062-.05z"
				/>
				<g transform="translate(46.453 46.975)">
					<mask id="prefix__b" fill="#fff">
						<use xlinkHref="#prefix__a" />
					</mask>
					<g fillRule="nonzero" mask="url(#prefix__b)">
						<g transform="translate(-24.895 -44.28)">
							<ellipse
								cx={123.284}
								cy={71.411}
								className="wpeform-logo__fill-orange"
								rx={58.611}
								ry={71.411}
							/>
							<ellipse
								cx={42.442}
								cy={92.968}
								className="wpeform-logo__fill-yellow"
								rx={42.442}
								ry={61.979}
							/>
							<ellipse
								cx={74.779}
								cy={173.811}
								className="wpeform-logo__fill-orange"
								rx={66.695}
								ry={66.021}
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
Logo.Text = Text;
