import { css, DefaultTheme } from 'styled-components';
import { paramCase } from 'change-case';
import type { ColorVariablesType } from './theme/types';

export function gv(key: keyof ColorVariablesType) {
	return `var(--${paramCase(key)})`;
}

export function getNodePathForDocPage(node: {
	fields?: { slug?: string; docType?: string };
	frontmatter?: { slug?: string };
}) {
	let docPath = node.fields?.slug ?? 'unknown';
	// now if frontmatter slug is present, then use that
	if (node?.frontmatter?.slug) {
		docPath = `/${node.fields?.docType ?? 'unknown'}/${node.frontmatter.slug}/`;
	}
	return `/docs${docPath}`;
}

/**
 * Given a number add the unit next to it so that it can be used as a CSS
 * value. Useful for creating font-size, padding, margin etc from numbers.
 *
 * @param size Font Size.
 * @param unit (Optional) CSS Unit, defaults to `'px'`.
 */
export function ns(
	size: number,
	unit: 'px' | 'em' | 'rem' | '%' | 's' | 'ms' = 'px'
): string {
	return `${size}${unit}`;
}

export function bp(
	props: { theme: { breakpoints: DefaultTheme['breakpoints'] } },
	breakpoint: keyof DefaultTheme['breakpoints']
) {
	return `screen and (min-width: ${ns(props.theme.breakpoints[breakpoint])})`;
}

export const screenReaderText = css`
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
`;

export const buttonResetCss = css`
	text-align: left;
	background: transparent;
	border: 0 none;
	outline: none;
	padding: 0;
	cursor: pointer;
	display: inline;
	font-style: normal;
	text-decoration: none;
	border-radius: 0;
`;

/**
 * Get multiple transition for CSS transition property.
 *
 * @param properties List of CSS Properties.
 * @param transition Transition value.
 */
export function getMultipleTransition(
	properties: string[],
	transition: string
): string {
	return properties.map(prop => `${prop} ${transition}`).join(', ');
}

export function getMultipleTransitionsWithWillChange(
	properties: string[],
	transition: string,
	additionalWillChanges?: string[]
): string {
	const willChanges = [...properties];
	if (additionalWillChanges) {
		willChanges.push(...additionalWillChanges);
	}
	return `${getMultipleTransition(
		properties,
		transition
	)}; will-change: ${willChanges.join(', ')};`;
}

export const proseCss = css`
	font-size: ${props => ns(props.theme.fz.large1)};
	color: ${gv('textColor')};
	line-height: 1.5;
	font-family: ${props => props.theme.fontFamilyBody};
	p,
	ul,
	ol,
	blockquote,
	li,
	strong,
	em,
	u,
	div.para {
		line-height: 1.5;
		font-size: inherit;
	}

	ul {
		list-style: disc;
	}
	ol {
		list-style: decimal;
	}

	blockquote {
		margin: 0 0 0.75em 0.75em;
		padding: 0.625em;
		border-left: 4px solid ${gv('borderColorSplit')};
		font-style: italic;
	}

	/* Anchors */
	a {
		color: ${gv('linkColor')};
		text-decoration: underline;
		&:hover {
			color: ${gv('linkHoverColor')};
			text-decoration: none;
		}
		&:visited {
			color: ${gv('primaryLightColor')};
		}
	}

	/* Copy & Lists */
	p,
	div.para {
		margin: 1.5em 0 0 0;
		&:only-child {
			margin: 0;
		}
		&:first-child {
			margin-top: 0;
		}
	}
	ul,
	ol {
		margin: 1.5em 0 1.5em 2.5em;
		padding: 0;
	}

	div.para > div.para:last-child {
		margin: 0;
	}

	ul ul,
	ol ul,
	ul ol,
	ol ol {
		margin-top: 0;
		margin-bottom: 0;
	}
	blockquote {
		margin: 1.5em 0;
	}
	/* Headings */
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 1.5em 0 0 0;
		line-height: 1.2;
		clear: both;
		font-family: ${props => props.theme.fontFamilyHeading};
	}
	h1 {
		font-size: ${props =>
			ns(props.theme.fz.large5 / props.theme.fz.base, 'em')};
		line-height: 1.2;
	}
	h2 {
		font-size: ${props =>
			ns(props.theme.fz.large3 / props.theme.fz.base, 'em')};
	}
	h3 {
		font-size: ${props =>
			ns(props.theme.fz.large2 / props.theme.fz.base, 'em')};
	}
	h4 {
		font-size: ${props =>
			ns(props.theme.fz.large1 / props.theme.fz.base, 'em')};
	}
	h5 {
		font-size: ${props =>
			ns(props.theme.fz.small1 / props.theme.fz.base, 'em')};
	}
	h6 {
		font-size: ${props =>
			ns(props.theme.fz.small2 / props.theme.fz.base, 'em')};
	}
	/* Tables */
	table {
		margin-top: ${props => ns(props.theme.gutter)};
		border-spacing: 0px;
		border-collapse: collapse;
		clear: both;
	}
	table td,
	table th {
		padding: 0;
		line-height: 1.5;
	}
	/* Code blocks */
	code:not([class*='language-']) {
		vertical-align: middle;
		color: ${gv('errorColor')};
		padding: 0.1em 0.25em;
		background-color: ${gv('disabledBackgroundColor')};
		word-break: break-word;
		hyphens: auto;
	}
	pre {
		max-height: 300px;
		width: 100%;
		overflow: auto;
		margin: 0.75em 0 0 0;
		color: ${gv('greyBgText')};
		background-color: ${gv('greyDarkColor')};
		padding: 0.3125em;
		tab-size: 2;
		a {
			color: ${gv('primaryBackgroundColor')};
			&:hover {
				color: ${gv('primaryLightColor')};
			}
		}
	}
	pre,
	code {
		font-family: ${props => props.theme.fontFamilyMono};
	}
	pre,
	pre code {
		color: ${gv('greyBgText')};
		background-color: ${gv('greyDarkColor')};
	}

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	blockquote,
	table,
	pre {
		&:first-child {
			margin-top: 0;
		}
	}

	strong {
		font-weight: bold;
	}
	u {
		text-decoration: underline;
	}
	em {
		font-style: italic;
	}

	img,
	.gatsby-resp-image-image,
	.gatsby-resp-image-background-image {
		border-radius: ${props => ns(props.theme.borderRadiusBase * 2)};
	}
	.gatsby-resp-image-wrapper {
		border-radius: ${props => ns(props.theme.borderRadiusBase * 2)};
		box-shadow: ${props => props.theme.boxShadowBase};
		margin: 0 0 ${props => ns(props.theme.gutter * 2)} 0;
		transition: ${props =>
			getMultipleTransitionsWithWillChange(
				['box-shadow'],
				props.theme.transitionControl
			)};

		&:hover {
			box-shadow: ${props => props.theme.boxShadow4dp};
		}
	}

	.anchor {
		position: absolute;
		height: 1em;
		width: 1em;
		top: 0;
		left: calc(-1em - 4px);
		color: ${gv('textColorSecondary')};
		opacity: 0;
		transition: ${props =>
			getMultipleTransitionsWithWillChange(
				['color', 'opacity'],
				props.theme.transitionControl
			)};
		&:hover {
			color: ${gv('textColorSecondary')};
		}
		svg {
			height: 1em;
			width: 1em;
			display: block;
			fill: currentColor;
		}
	}
	h2,
	h3,
	h4 {
		scroll-margin-top: ${props => ns(props.theme.controlHeightBase * 1.5 + 10)};
		&:hover {
			.anchor {
				opacity: 1;
			}
		}
		@media ${props => bp(props, 'desktop')} {
			scroll-margin-top: ${props => ns(props.theme.controlHeightBase * 2 + 10)};
		}
	}

	table {
		border: 0 none;
		border-collapse: collapse;
		width: 100%;
		font-size: ${props => ns(props.theme.fz.base)};
		text-align: left;
		th,
		td {
			// prettier-ignore
			padding: ${props => ns(props.theme.gutter)} ${props =>
				ns(props.theme.gutter / 2)};
		}

		thead,
		tfoot {
			background-color: ${gv('backgroundHover')};
			tr {
				border-bottom: 1px solid ${gv('borderColorBase')};
			}
			th {
				font-size: ${props => ns(props.theme.fz.small1)};
				color: ${gv('textColorSecondary')};
				padding-top: ${props => ns(props.theme.gutter / 2)};
				padding-bottom: ${props => ns(props.theme.gutter / 2)};
				font-family: ${props => props.theme.fontFamilyHeading};
				&:first-child {
					padding-left: ${props => ns(props.theme.gutter)};
				}
			}
		}
		tfoot {
			tr {
				border-top: 1px solid ${gv('borderColorBase')};
				border-bottom: 0 none;
			}
		}

		th {
			color: ${gv('headingColor')};
			font-weight: bold;
		}

		tbody {
			tr {
				border-bottom: 1px solid ${gv('borderColorSplit')};
				transition: ${props =>
					getMultipleTransitionsWithWillChange(
						['background-color'],
						props.theme.transitionControl
					)};
				background-color: ${gv('backgroundShade')};
				th:first-child {
					padding-left: ${props => ns(props.theme.gutter)};
					color: ${gv('headingColor')};
					font-family: ${props => props.theme.fontFamilyHeading};
				}
			}
		}

		&.centered {
			tbody {
				td {
					text-align: center;
				}
			}
			thead,
			tfoot {
				th {
					text-align: center;
				}
			}
		}
	}
`;

export const docArticleContainerCss = css`
	padding: ${props => ns(props.theme.gutter * 2)};
	width: 100%;
	max-width: 800px;
`;

export const generalSectionCss = css`
	padding: ${props => ns(props.theme.gutter * 2)};
	width: 100%;
	max-width: 1024px;
`;
export const wideSectionCss = css`
	// prettier-ignore
	padding: ${props => ns(props.theme.gutter * 4)} ${props =>
		ns(props.theme.gutter * 2)};
	width: 100%;
`;

export const constrainCss = css`
	max-width: 1200px;
	margin: 0 auto;
`;
export const gridContainerCss = css`
	display: flex;
	flex-flow: row wrap;
	margin-left: ${props => ns(props.theme.gutter * -1)};
	margin-right: ${props => ns(props.theme.gutter * -1)};
`;

export const containerAlignCss = css`
	margin: ${props => ns(props.theme.gutter * 2)} 0;
	padding: ${props => ns(props.theme.gutter * 1.5)};
`;

export const sharedScrollBarCss = css`
	&::-webkit-scrollbar-thumb {
		background-color: ${gv('borderColorSplit')};
		&:hover,
		&:active {
			background-color: ${gv('borderColorBase')};
		}
	}
`;
export const sharedHorizontalScrollBarCss = css`
	overflow-x: auto;
	&::-webkit-scrollbar {
		background-color: ${gv('backgroundHover')};
		height: ${props => ns(props.theme.gutter / 2)};
	}
`;

export const scrollBarHorizontalCss = css`
	${sharedHorizontalScrollBarCss}
	${sharedScrollBarCss};
`;

export const sharedVerticalScrollBarCss = css`
	overflow-y: auto;
	&::-webkit-scrollbar {
		background-color: ${gv('backgroundHover')};
		width: ${props => ns(props.theme.gutter / 2)};
	}
`;
export const scrollBarVerticalCss = css`
	${sharedVerticalScrollBarCss};
	${sharedScrollBarCss};
`;

export function getRandomInRange(from: number, to: number) {
	return Math.floor(Math.random() * (to - from) + from);
}

export const angleMaskCss = css`
	transform-origin: 100% 0;
	transform: translate3d(0, 0, 0) skewY(-6deg);
`;

export const controlSkewCss = css`
	transform-origin: center center;
	transform: translate3d(0, 0, 0) skewX(-6deg);
`;

export const controlSkewReverseCss = css`
	transform-origin: center center;
	transform: translate3d(0, 0, 0) skewX(6deg);
	display: inline-block;
`;

export function setCookie(name: string, value: string, days: number) {
	let expires = '';
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toUTCString();
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
export function getCookie(name: string) {
	const nameEQ = name + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		// eslint-disable-next-line eqeqeq
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

/**
 * Download a virtual file with text.
 *
 * @param filename Filename for download.
 * @param text Text inside the file.
 * @returns void
 */
export function download(filename: string, text: string) {
	if (typeof window === 'undefined') {
		return;
	}
	var pom = document.createElement('a');
	pom.setAttribute(
		'href',
		'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
	);
	pom.setAttribute('download', filename);

	if (document.createEvent) {
		var event = document.createEvent('MouseEvents');
		event.initEvent('click', true, true);
		pom.dispatchEvent(event);
	} else {
		pom.click();
	}
}
