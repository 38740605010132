import { ReactNode } from 'hoist-non-react-statics/node_modules/@types/react';
import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {
	buttonResetCss,
	getMultipleTransitionsWithWillChange,
	gv,
	ns,
} from '../../utils';
import { Link } from 'gatsby';

const Prefix = styled.span`
	flex: 0 0 auto;
	margin: 0 0.5em 0 0;
	line-height: 1;
	svg {
		display: block;
	}
	&:only-child {
		margin: 0;
	}
`;
const Suffix = styled.span`
	flex: 0 0 auto;
	margin: 0 0 0 0.5em;
	line-height: 1;
	svg {
		display: block;
	}

	&:only-child {
		margin: 0;
	}
`;
const Text = styled.span`
	flex: 0 0 auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1;
`;

const StyledButton = styled.button`
	${buttonResetCss};
	min-width: 80px;
	font-size: ${props => ns(props.theme.fz.base)};
	padding: 0 ${props => ns(props.theme.gutter)};
	height: ${props => ns(props.theme.controlHeightBase)};
	border-radius: ${props => ns(props.theme.borderRadiusBase)};
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	--background-color: ${gv('backgroundControl')};
	--border-color: ${gv('backgroundControl')};
	--color: ${gv('textColor')};
	--background-hover-color: ${gv('backgroundHover')};
	--border-hover-color: ${gv('backgroundHover')};
	--background-active-color: ${gv('backgroundSelected')};
	--border-active-color: ${gv('backgroundSelected')};

	background-color: var(--background-color);
	border: 1px solid var(--border-color);
	color: var(--color);
	box-shadow: ${props => props.theme.boxShadowNoneFocus},
		inset 0 0 0 rgba(0, 0, 0, 0);

	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['background-color', 'color', 'border-color', 'box-shadow'],
			props.theme.transition
		)};

	&:hover {
		background-color: var(--background-hover-color);
		border: 1px solid var(--border-hover-color);
		color: var(--color);
	}

	&:focus-visible {
		background-color: var(--background-active-color);
		border: 1px solid var(--border-active-color);
		box-shadow: ${gv('boxShadowControlFocus')}, ${gv('boxShadowInset')};
		color: var(--color);
	}
	&:active,
	&.active {
		background-color: var(--background-active-color);
		border: 1px solid var(--border-active-color);
		${Text},
		${Prefix},
		${Suffix} {
			transform: scale(0.95);
		}
	}

	// types
	&.type-primary {
		--background-color: ${gv('primaryColor')};
		--border-color: ${gv('primaryColor')};
		--color: ${gv('primaryBgText')};
		--background-hover-color: ${gv('primaryLightColor')};
		--border-hover-color: ${gv('primaryLightColor')};
		--background-active-color: ${gv('primaryDarkColor')};
		--border-active-color: ${gv('primaryDarkColor')};
	}
	&.type-ghost {
		--background-color: ${gv('appBackgroundColor')};
		--border-color: ${gv('appBackgroundColor')};
		--color: ${gv('textColor')};
		--background-hover-color: ${gv('backgroundHover')};
		--border-hover-color: ${gv('backgroundHover')};
		--background-active-color: ${gv('backgroundSelected')};
		--border-active-color: ${gv('backgroundSelected')};
	}

	&.type-transparent {
		--background-color: rgba(0, 0, 0, 0);
		--border-color: rgba(0, 0, 0, 0);
		--color: inherit;
		--background-hover-color: rgba(0, 0, 0, 0.05);
		--border-hover-color: rgba(0, 0, 0, 0);
		--background-active-color: rgba(0, 0, 0, 0.2);
		--border-active-color: rgba(0, 0, 0, 0);
	}

	// sizes
	&.size-small {
		min-width: 50px;
		font-size: ${props => ns(props.theme.fz.small2)};
		font-weight: bold;
		padding: 0 ${props => ns(props.theme.gutter * 0.75)};
		height: ${props => ns(props.theme.controlHeightBase * 0.75)};
	}
	&.size-icon {
		min-width: unset;
		width: 2.5em;
		height: 2.5em;
		font-size: ${props => ns(props.theme.fz.small2)};
		font-weight: bold;
		padding: 0;
	}

	// overrride for disabled
	&:disabled {
		box-shadow: none;
		cursor: not-allowed;
		--background-color: ${gv('disabledBackgroundColor')};
		--border-color: ${gv('disabledColor')};
		--color: ${gv('disabledColor')};
		--background-hover-color: ${gv('disabledBackgroundColor')};
		--border-hover-color: ${gv('disabledColor')};
		--background-active-color: ${gv('disabledBackgroundColor')};
		--border-active-color: ${gv('disabledColor')};
		&:active,
		&.active {
			${Text},
			${Prefix},
			${Suffix} {
				transform: scale(1);
			}
		}
	}
`;

type GroupProps = {
	align?: 'center' | 'flex-start' | 'flex-end' | 'justify';
};

const Group = styled.nav<GroupProps>`
	margin-left: ${props => ns(props.theme.gutter * -0.5)};
	margin-right: ${props => ns(props.theme.gutter * -0.5)};
	display: flex;
	align-items: center;
	flex-flow: row wrap;
	justify-content: ${props => props.align ?? 'center'};
	> * {
		flex: ${props => (props.align === 'justify' ? 1 : 0)} 0 auto;
		margin: ${props => ns(props.theme.gutter * 0.5)};
	}
`;

export type ButtonProps = {
	children: ReactNode;
	prefix?: ReactNode;
	suffix?: ReactNode;
	size?: 'small' | 'regular' | 'icon';
	type?: 'regular' | 'primary' | 'ghost' | 'transparent';
	className?: string;
	title?: string;
} & (
	| {
			as: 'button';
			disabled?: boolean;
			onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	  }
	| { as: 'link'; href: string; target?: string; rel?: string }
);
export default function Button(props: ButtonProps) {
	const {
		children,
		prefix,
		suffix,
		size = 'regular',
		type = 'regular',
		className,
		title,
	} = props;

	const calculatedClass = classNames(className, {
		[`size-${size}`]: true,
		[`type-${type}`]: true,
		'has-prefix': !!prefix,
		'has-suffix': !!suffix,
	});

	const innerChild = (
		<>
			{prefix ? <Prefix>{prefix}</Prefix> : null}
			<Text>{children}</Text>
			{suffix ? <Suffix>{suffix}</Suffix> : null}
		</>
	);

	if (props.as === 'button') {
		return (
			<StyledButton
				onClick={e => {
					e.preventDefault();
					props.onClick(e);
				}}
				className={calculatedClass}
				title={title}
				disabled={props.disabled}
			>
				{innerChild}
			</StyledButton>
		);
	}
	if (
		props.href.startsWith('http://') ||
		props.href.startsWith('https://') ||
		props.href.startsWith('//')
	) {
		return (
			<StyledButton
				className={calculatedClass}
				as="a"
				href={props.href}
				target={props.target}
				rel={props.rel}
				title={title}
			>
				{innerChild}
			</StyledButton>
		);
	}

	return (
		<StyledButton
			as={Link}
			to={props.href}
			className={calculatedClass}
			title={title}
		>
			{innerChild}
		</StyledButton>
	);
}
Button.Group = Group;
