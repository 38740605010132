import React, { useState, useEffect, PropsWithChildren } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import { darkTheme, lightTheme } from '../../theme';
import GlobalStyle from './GlobalStyle';
import DarkModeToggler from '../DarkModeToggler';
import Footer from '../Footer';
import 'modern-normalize';
import { gv } from '../../utils';
import { siteThemeContext } from '../../theme/Provider';
import CookieConscent from '../CookieConscent';

// load fonts
// Nunito:ital,wght@0,400;0,700;1,400;1,700
import '@fontsource/nunito/800.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/400-italic.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/700-italic.css';
import ExitCoupon from '../ExitCoupon';

const Main = styled.main``;
const Content = styled.section`
	background-color: ${gv('appBackgroundColor')};
`;
const Body = styled.div`
	min-height: calc(100vh - 219px);
`;

const THEME_STORAGE = 'wpeform-doc-theme';

export default function Layout({
	children,
	location,
}: PropsWithChildren<PageProps>) {
	// start with a light mode
	const [isDarkTheme, setIsDarkTheme] = useState<boolean | null>(null);
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const handler = (e: MediaQueryListEvent) => {
				setIsDarkTheme(e.matches);
			};
			// first set the current mode
			let isDark =
				window.matchMedia &&
				window.matchMedia('(prefers-color-scheme: dark)').matches;
			// override if already set in localStorage
			const persistedTheme = window.localStorage.getItem(THEME_STORAGE);
			if (persistedTheme === 'dark') {
				isDark = true;
			} else if (persistedTheme === 'light') {
				isDark = false;
			}
			setIsDarkTheme(isDark);
			// add event listener
			window
				.matchMedia('(prefers-color-scheme: dark)')
				.addEventListener('change', handler);
			return () => {
				window
					.matchMedia('(prefers-color-scheme: dark)')
					.removeEventListener('change', handler);
			};
		}
		return () => {};
	}, []);
	// keep theme persisted in localStorage
	useEffect(() => {
		if (typeof window !== 'undefined' && isDarkTheme !== null) {
			window.localStorage.setItem(
				THEME_STORAGE,
				isDarkTheme ? 'dark' : 'light'
			);
			if (isDarkTheme) {
				document.body.classList.add('dark');
			} else {
				document.body.classList.remove('dark');
			}
		}
	}, [isDarkTheme]);

	return (
		<siteThemeContext.Provider value={isDarkTheme}>
			<ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
				<GlobalStyle />
				<Helmet>
					<meta name="color-scheme" content="dark light" />
				</Helmet>
				<Main>
					<Header
						actions={
							<DarkModeToggler
								isDarkMode={isDarkTheme}
								setIsDarkMode={setIsDarkTheme}
							/>
						}
						location={location}
					/>
					<Content>
						<Body>{children}</Body>
						<Footer />
					</Content>
				</Main>
				<CookieConscent />
				<ExitCoupon />
			</ThemeProvider>
		</siteThemeContext.Provider>
	);
}
