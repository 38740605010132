import React, { useEffect, useState } from 'react';
import exitIntent from 'exit-intent-mobile-bugfix';
import Cta from '../Cta';
import Modal from '../Modal';
import { Highlighted } from '../Typography';
import { ListItem, UlList } from '../Typography/List';
import { ReactComponent as HandShake } from './HandShake.svg';
import styled from 'styled-components';
import { gv, ns } from '../../utils';

const MainHeading = styled.p`
	font-family: ${props => props.theme.fontFamilyLogo};
	font-size: ${props => ns(props.theme.fz.large5)};
	line-height: 1.3;
	text-align: center;
	font-weight: 900;
	color: ${gv('headingColor')};
	margin: 0 0 ${props => ns(props.theme.gutter)} 0;
`;

const SecondaryHeading = styled.p`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.large2)};
	line-height: 1.3;
	text-align: center;
	font-weight: bold;
	color: ${gv('headingColor')};
	margin: 0 0 ${props => ns(props.theme.gutter)} 0;
`;

const Description = styled.p`
	font-family: ${props => props.theme.fontFamilyBody};
	font-size: ${props => ns(props.theme.fz.large1)};
	line-height: 1.5;
	text-align: center;
	font-weight: normal;
	color: ${gv('proseColor')};
	margin: ${props => ns(props.theme.gutter)} 0;
`;

const IconContainer = styled.div`
	margin: 0 0 ${props => ns(props.theme.gutter * 1.5)};
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${gv('borderColorSplit')};
	svg {
		fill: currentColor;
		width: 128px;
		height: auto;
	}
`;

export default function ExitCoupon() {
	const [show, setShow] = useState<boolean>(false);
	useEffect(() => {
		if (typeof window === 'undefined') {
			return () => {};
		}
		// don't show this if we are on docs or blog page
		if (
			window.location.pathname.includes('blog') ||
			window.location.pathname.includes('docs')
		) {
			return () => {};
		}
		const handler = () => {
			if (
				window.location.pathname.includes('blog') ||
				window.location.pathname.includes('docs')
			) {
				return;
			}
			setShow(true);
		};
		let destroy: (() => void) | null = null;
		// initialize exit intent only after 20 seconds
		const timeoutId = setTimeout(() => {
			destroy = exitIntent({
				onExitIntent: handler,
				showAfterInactiveSecondsDesktop: 60,
				showAfterInactiveSecondsMobile: 40,
				showAgainAfterSeconds: 30,
				maxDisplays: process.env.NODE_ENV === 'development' ? 0 : 1,
			});
		}, 15000);
		return () => {
			clearTimeout(timeoutId);
			destroy?.();
		};
	}, []);

	return (
		<Modal type="attentive" isOpen={show} setIsOpen={setShow} maxWidth="450px">
			<MainHeading>Not sure if this product is for you?</MainHeading>
			<UlList className="attentive">
				<ListItem arrow="check">30 Days Money Back Guarantee.</ListItem>
				<ListItem arrow="check">Reasonable pricing.</ListItem>
				<ListItem arrow="check">Actively developed with new features.</ListItem>
			</UlList>
			<IconContainer>
				<HandShake />
			</IconContainer>
			<SecondaryHeading>and a 30% discount coupon</SecondaryHeading>
			<Highlighted>GET30WPEFORM</Highlighted>
			<Description className="centered">
				Enter the coupon code during checkout and get 30% off your first year!
			</Description>
			<Cta.Group size="regular" noGutterTop>
				<Cta size="regular" type="primary" to="/pricing/">
					Get Started
				</Cta>
			</Cta.Group>
		</Modal>
	);
}
