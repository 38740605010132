import styled from 'styled-components';
import {
	bp,
	constrainCss,
	containerAlignCss,
	ns,
	wideSectionCss,
} from '../../utils';

export const GeneralBody = styled.div`
	${containerAlignCss};
	&.wide {
		padding-top: ${props => ns(props.theme.gutter * 3)};
		padding-bottom: ${props => ns(props.theme.gutter * 3)};
	}
	scroll-margin-top: ${props => ns(props.theme.controlHeightBase * 1.5 + 10)};
	@media ${props => bp(props, 'desktop')} {
		scroll-margin-top: ${props => ns(props.theme.controlHeightBase * 2 + 10)};
	}
	&.blog {
		margin-top: 0;
	}
`;

export const WideSection = styled.section`
	${wideSectionCss};
`;
export const Constrain = styled.div`
	${constrainCss};
	&.narrow {
		max-width: 960px;
	}
	&.wide {
		max-width: 1600px;
	}
`;
