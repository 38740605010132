import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import {
	getMultipleTransitionsWithWillChange,
	ns,
	gv,
	constrainCss,
	bp,
} from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import LogoSvg from '../Logo';

const Logo = styled.div`
	svg {
		height: 64px;
		width: auto;
		display: block;
	}
`;
const LogoText = styled.p`
	font-size: ${props => ns(props.theme.fz.large3)};
	margin: ${props => ns(props.theme.gutter / 2)} 0 0 0;
`;
const License = styled.p`
	margin: ${props => ns(props.theme.gutter / 2)} 0 0 0;
	padding: 0 0 ${props => ns(props.theme.gutter / 2)} 0;
	.heart {
		color: ${gv('errorColor')};
	}
	a {
		display: block;
		padding: ${props => ns(props.theme.gutter / 2)} 0;
		color: ${gv('textColorSecondary')};
		&:hover {
			color: ${gv('textColor')};
			text-decoration: underline;
		}
	}
`;
const FooterLeft = styled.div`
	max-width: 300px;
	flex: 0 0 auto;
	padding: ${props => ns(props.theme.gutter)};
`;

const FooterExtroTitle = styled.h4`
	font-weight: bold;
	color: ${gv('textColor')};
	font-size: ${props => ns(props.theme.fz.large1)};
	margin: 0 0 ${props => ns(props.theme.gutter * 2)} 0;
`;
const FooterExtro = styled.p`
	color: ${gv('textColorSecondary')};
	margin: 0 0 ${props => ns(props.theme.gutter)} 0;
	line-height: 1.5;
`;

const FooterGridTitle = styled.p`
	color: ${gv('textColorSecondary')};
	margin: 0 0 ${props => ns(props.theme.gutter / 2)} 0;
	text-transform: uppercase;
	font-weight: bold;
`;
const Menu = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;
const MenuItem = styled.li`
	list-style: none;
	a {
		display: block;
		padding: ${props => ns(props.theme.gutter / 2)} 0;
		color: ${gv('textColorSecondary')};
		&:hover {
			color: ${gv('textColor')};
			text-decoration: underline;
		}
	}
`;
const FooterGridItem = styled.aside`
	grid-column: auto / span 12;
	@media ${props => bp(props, 'tablet')} {
		grid-column: auto / span 6;
	}
	@media ${props => bp(props, 'desktop')} {
		grid-column: auto / span 4;
		&:last-child {
			grid-column: auto / span 12;
		}
	}
	@media ${props => bp(props, 'desktopHd')} {
		grid-column: auto / span 3;
		&:last-child {
			grid-column: auto / span 3;
		}
	}
`;

const FooterGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: ${props => ns(props.theme.gutter)};
`;

const FooterRight = styled.div`
	padding: ${props => ns(props.theme.gutter)};
	flex: 1 0 auto;
	width: 600px;
	max-width: 100%;
`;

const Container = styled.footer`
	background-color: ${gv('backgroundShade')};
	width: 100%;
	padding: ${props => ns(props.theme.gutter)};
	font-family: ${props => props.theme.fontFamilyHeading};
`;
const Body = styled.div`
	${constrainCss};
	display: flex;
	flex-flow: row wrap;
`;

const SocialList = styled.ul`
	list-style: none;
	margin: ${props => ns(props.theme.gutter / 2)} 0 0 0;
	padding: 0;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;
	li {
		list-style: none;
		margin: 0;
		padding: 0;
		margin: ${props => ns(props.theme.gutter * 0.25)};
		a {
			font-size: ${props => ns(props.theme.fz.large3)};
			display: inline-block;
			text-decoration: none;
			color: ${gv('textColorSecondary')};
			transition: ${props =>
				getMultipleTransitionsWithWillChange(
					['color'],
					props.theme.transitionControl
				)};
			&:hover {
				color: ${gv('primaryLightColor')};
			}
		}
	}
`;

export default function Footer() {
	const data = useStaticQuery<GatsbyTypes.FooterDataQuery>(graphql`
		query FooterData {
			site {
				siteMetadata {
					title
					description
					social {
						twitter
						github
						youtube
					}
				}
			}
		}
	`);
	return (
		<Container>
			<Body>
				<FooterLeft>
					<Logo>
						<LogoSvg />
						<LogoText>
							<LogoSvg.Text hasIo />
						</LogoText>
					</Logo>
					<License>&copy; 2020 - {new Date().getFullYear()} WPEForm.io</License>
					<SocialList>
						<li>
							<a
								href={data.site?.siteMetadata?.social?.twitter}
								aria-label="Twitter account of WPEForm founder"
							>
								<FontAwesomeIcon icon={faTwitter} aria-label="Twitter Icon" />
							</a>
						</li>
						<li>
							<a
								href={data.site?.siteMetadata?.social?.youtube}
								aria-label="Youtube account of WPEForm"
							>
								<FontAwesomeIcon icon={faYoutube} aria-label="YouTube Icon" />
							</a>
						</li>
						<li>
							<a
								href={data.site?.siteMetadata?.social?.github}
								aria-label="Github account of WPEForm founder"
							>
								<FontAwesomeIcon icon={faGithub} aria-label="GitHub Account" />
							</a>
						</li>
					</SocialList>
					<License>
						Made with{' '}
						<FontAwesomeIcon
							className="heart"
							aria-label="Love"
							icon={faHeart}
						/>{' '}
						in India.
						<br />
						<Link to="/credits/">Credits &amp; Attribution.</Link>
					</License>
				</FooterLeft>
				<FooterRight>
					<FooterExtroTitle>{data.site?.siteMetadata?.title}</FooterExtroTitle>
					<FooterGrid>
						<FooterGridItem>
							<FooterGridTitle>Product</FooterGridTitle>
							<Menu>
								<MenuItem>
									<Link to="/pricing/">Pricing</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/features/">Features</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/examples/">Examples</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/docs/">Documentation</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/account/">Customer Login</Link>
								</MenuItem>
							</Menu>
						</FooterGridItem>
						<FooterGridItem>
							<FooterGridTitle>Website</FooterGridTitle>
							<Menu>
								<MenuItem>
									<Link to="/blog/">Blog</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/contact/">Contact Us</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/about/">About Us</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/tos/">Terms</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/privacy/">Privacy</Link>
								</MenuItem>
							</Menu>
						</FooterGridItem>
						<FooterGridItem>
							<FooterGridTitle>Resources</FooterGridTitle>
							<Menu>
								<MenuItem>
									<Link to="/faqs/">FAQs</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/licensing/">Licensing</Link>
								</MenuItem>
								<MenuItem>
									<Link to="/tools/">Developer Tooling</Link>
								</MenuItem>
								<MenuItem>
									<a
										href="https://wpack.io/"
										target="_blank"
										rel="noopener noreferrer friend"
									>
										WordPress Tooling
									</a>
								</MenuItem>
								<MenuItem>
									<a
										href="https://www.npmjs.com/package/@wpeform/react"
										target="_blank"
										rel="noopener noreferrer nofollow friend"
									>
										React Form SDK
									</a>
								</MenuItem>
							</Menu>
						</FooterGridItem>
						<FooterGridItem>
							<FooterGridTitle>Goal</FooterGridTitle>
							<FooterExtro>{data.site?.siteMetadata?.description}</FooterExtro>
						</FooterGridItem>
					</FooterGrid>
					<Menu></Menu>
				</FooterRight>
			</Body>
		</Container>
	);
}
