import { createGlobalStyle } from 'styled-components';
import { cssVariables } from '../../theme/generate';
import { bp, getMultipleTransitionsWithWillChange, gv, ns } from '../../utils';

const GlobalStyle = createGlobalStyle`
	:root {
		color-scheme: 'dark light'; /* both supported */
	}
	html, body {
		background-color: ${gv('appBackgroundColor')};
		color: ${gv('textColor')};
	}
	a {
		color: ${gv('linkColor')};
		text-decoration: none;
		&:hover {
			color: ${gv('linkHoverColor')};
		}
	}
	html {
		scroll-behavior: smooth;
	}
	@media screen and (prefers-reduced-motion: reduce) {
		html {
			scroll-behavior: auto;
		}
	}

	// style scrollbar
	::-webkit-scrollbar-thumb {
		background-color: ${gv('borderColorSplit')};
		&:hover,
		&:active {
			background-color: ${gv('borderColorBase')};
		}
	}
	::-webkit-scrollbar {
		background-color: ${gv('backgroundHover')};
		width: ${props => ns(props.theme.gutter / 2)};
		height: ${props => ns(props.theme.gutter / 2)};
	}

	// nprogress
	body #nprogress .bar {
		z-index: 2147483647 !important;
	}

	// React Modal
	body .wpeform-site-modal-overlay {
		z-index: 999999;
		background-color: rgba(0, 0, 0, 0.01);
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		transition: ${props =>
			getMultipleTransitionsWithWillChange(
				['background-color'],
				props.theme.transition
			)};

		&.ReactModal__Overlay--after-open {
			background-color: rgba(0, 0, 0, 0.8);
		}
		&.ReactModal__Overlay--before-close {
			background-color: rgba(0, 0, 0, 0);
		}
	}
	body .wpeform-site-modal {
		position: absolute;
		top: ${props => ns(props.theme.gutter * 2)};
		bottom: ${props => ns(props.theme.gutter * 2)};
		left: ${props => ns(props.theme.gutter * 2)};
		right: ${props => ns(props.theme.gutter * 2)};
		background: ${gv('appBackgroundColor')};
		box-shadow: ${props => props.theme.boxShadow4dp};
		overflow: auto;
		outline: none;
		padding: ${props => ns(props.theme.gutter)};
		@media ${props => bp(props, 'desktop')} {
			top: ${props => ns(props.theme.gutter * 5)};
			bottom: ${props => ns(props.theme.gutter * 5)};
			left: ${props => ns(props.theme.gutter * 5)};
			right: ${props => ns(props.theme.gutter * 5)};
		}
		margin: 0 auto;
		&.wpeform-site-modal--youtube {
			background-color: transparent;
			box-shadow: none;
			padding: 0;
			overflow: visible;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&.wpeform-site-modal--iframe {
			padding: 0;
		}

		&.wpeform-site-modal--lightbox {
			padding: 0;
			overflow: hidden;
			border-radius: ${props => ns(props.theme.borderRadiusBase * 2)};
			display: flex;
			flex-flow: column nowrap;
			@media ${props => bp(props, 'desktop')} {
				top: ${props => ns(props.theme.gutter * 3)};
				bottom: ${props => ns(props.theme.gutter * 3)};
				left: ${props => ns(props.theme.gutter * 3)};
				right: ${props => ns(props.theme.gutter * 3)};
			}
			@media screen and (max-width: ${props =>
				ns(props.theme.breakpoints.tablet - 1)}) {
				top: 32px;
				left: 0;
				right: 0;
				bottom: 0;
				border-radius: 0;

				.ReactModal__Content__CloseButton {
					display: block;
					width: 100%;
					left: 0;
					top: 0;
					height: 32px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: ${gv('greyDarkColor')};
					color: ${gv('primaryBgText')};
					font-size: ${props => ns(props.theme.fz.small1)};
					font-weight: bold;
					.text {
						clip-path: unset;
						clip: unset;
						height: auto;
						margin: 0 0 0 0.25em;
						position: static;
						width: auto;
					}
				}
			}
		}

		&.wpeform-site-modal--attentive {
			padding: 0;
			overflow: initial;
			bottom: auto;
			background-color: transparent;
			box-shadow: none;
		}

		// animated in
		opacity: 0;
		transition: ${props =>
			getMultipleTransitionsWithWillChange(
				['opacity'],
				props.theme.transition
			)};
		&.ReactModal__Content--after-open {
			opacity: 1;
		}
		&.ReactModal__Content--before-close {
			opacity: 0;
		}
	}
	body.ReactModal__Body--open {
		overflow: hidden !important;
	}

	// dark lightbox
	body.dark {
		.ReactModal__LightBox__Content {
			background-color: #111;
		}
	}

	${cssVariables};

	// Logo
	.wpeform-logo {
		.wpeform-logo__fill-bg {
			fill: var(--logo-fill-bg);
		}
		.wpeform-logo__fill-orange {
			fill: var(--logo-fill-orange);
		}
		.wpeform-logo__fill-yellow {
			fill: var(--logo-fill-yellow);
		}
	}
	:root {
		--logo-fill-bg: #1F2933;
		--logo-fill-orange: #F35627;
		--logo-fill-yellow: #F0B429;
	}
	body.dark {
		--logo-fill-bg: #52606D;
		--logo-fill-orange: #F9703E;
		--logo-fill-yellow: #F7C948;

	}

	::selection {
		background-color: ${gv('primaryDarkColor')};
		color: ${gv('primaryBgText')};
	}
`;

export default GlobalStyle;
