import React from 'react';
import styled from 'styled-components';
import { buttonResetCss, gv } from '../../utils';

const Toggler = styled.button`
	${buttonResetCss};
	height: 26px;
	width: 26px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	// SUN
	.sun {
		background: var(--dn-background-color, ${gv('appBackgroundColor')});
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: 2px solid ${gv('warningColor')};
		box-shadow: 0 0 0 2px
			var(--dn-background-color, ${gv('appBackgroundColor')});
	}
	.sun__ray {
		width: 2px;
		background: ${gv('warningColor')};
		display: block;
		height: 100%;
		position: absolute;
		z-index: -1;
		transition: 0.4s all, height 0.3s ease-in-out;
		${props => {
			const columns = 8;
			let output: string = '';
			for (let i = 1; i <= columns; i++) {
				output += `&:nth-child(${i}) {
        transform: rotate(calc(${i} * calc(360deg / ${columns})));
      }`;
			}
			return output;
		}};
	}
	&:hover .sun__ray {
		${props => {
			const columns = 8;
			let output: string = '';
			for (let i = 1; i <= columns; i++) {
				output += `&:nth-child(${i}) {
					transform: rotate(
          calc(calc(${i} * calc(360deg / ${columns})) - 20deg)
        );
					}`;
			}
			return output;
		}};
	}

	// Hide if in unknown status
	&.theme--unknown {
		visibility: hidden;
	}

	// MOON
	.moon {
		height: 20px;
		width: 20px;
		position: absolute;
		background: var(--dn-background-color, ${gv('appBackgroundColor')});
		border-radius: 50%;
		top: 0;
		right: 0;
		transform: scale(0) translate(25%, -25%);
		z-index: 9;
		transition: 0.4s transform;
		transform-origin: right;
	}

	// NIGHT MODE
	&.theme--night {
		// SUN
		.sun {
			background-color: ${gv('darkTextColor')};
			border-color: ${gv('darkTextColor')};
			width: 20px;
			height: 20px;
		}
		.sun__ray {
			background: ${gv('darkTextColor')};
			height: 0;
			transition: 0.4s, transform 0.4s, height 0.2s 0.1s;

			${props => {
				const columns = 8;
				let output: string = '';
				for (let i = 1; i <= columns; i++) {
					output += `&:nth-child(${i}) {
							transform: rotate(
										calc(calc(${i} * calc(360deg / ${columns})) - 45deg)
								);
							}`;
				}
				return output;
			}};
		}

		// MOON
		.moon {
			transform: scale(1) translate(8%, -12%);
		}
		&:hover {
			.moon {
				transform: scale(1) translate(-8%, -26%);
			}
		}
	}
`;

export type DarkModeTogglerProps = {
	isDarkMode: boolean | null;
	setIsDarkMode: React.Dispatch<React.SetStateAction<boolean | null>>;
};
export default function DarkModeToggler(props: DarkModeTogglerProps) {
	const { isDarkMode, setIsDarkMode } = props;
	return (
		<Toggler
			className={`theme-toggle theme--${
				isDarkMode === null ? 'unknown' : isDarkMode ? 'night' : 'day'
			}`}
			onClick={e => {
				e.preventDefault();
				setIsDarkMode(mode => !mode);
			}}
			aria-label={`${
				isDarkMode ? 'Activate Light Theme' : 'Activate Dark Theme'
			}`}
		>
			<span className="moon" />
			<span className="sun" />
			<small className="sun__ray" />
			<small className="sun__ray" />
			<small className="sun__ray" />
			<small className="sun__ray" />
		</Toggler>
	);
}
