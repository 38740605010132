import React, { forwardRef, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import {
	bp,
	buttonResetCss,
	getMultipleTransitionsWithWillChange,
	gridContainerCss,
	gv,
	ns,
} from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { ReactNode } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

const GenericSkewReverse = styled.div`
	display: block;
`;

export const ControlButton = styled.button`
	${buttonResetCss};
	--bg-color: ${gv('primaryColor')};
	--bg-hover-color: ${gv('primaryLightColor')};
	--bg-active-color: ${gv('primaryDarkColor')};
	--color: ${gv('primaryBgText')};
	--box-shadow-color: ${gv('controlShadowPrimary')};
	--box-shadow-offset: 2px;
	--box-shadow-offset-hover: 6px;
	--box-shadow-offset-active: 2px;
	--border-color: ${gv('primaryColor')};
	--border-hover-color: ${gv('primaryLightColor')};
	--border-active-color: ${gv('primaryDarkColor')};

	display: inline-flex;
	padding: 0 ${props => ns(props.theme.gutter * 2)};
	align-items: center;
	justify-content: center;
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['box-shadow', 'border-color', 'background-color'],
			props.theme.transitionControl
		)};

	background-color: var(--bg-color);
	color: var(--color);
	font-weight: bold;
	box-shadow: var(--box-shadow-offset) var(--box-shadow-offset) 0 0
		var(--box-shadow-color);
	border: 1px solid var(--border-color);
	height: ${props => ns(props.theme.controlHeightBase)};

	&:hover {
		color: var(--color);
		box-shadow: var(--box-shadow-offset-hover) var(--box-shadow-offset-hover) 0
			0 var(--box-shadow-color);
		border-color: var(--border-hover-color);
		background-color: var(--bg-hover-color);
	}
	&:active,
	&:focus,
	&.focus {
		border-color: var(--border-active-color);
		background-color: var(--bg-active-color);
		color: var(--color);
		box-shadow: var(--box-shadow-offset-active) var(--box-shadow-offset-active)
			0 0 var(--box-shadow-color);
		transition: none;
	}

	&.small {
		height: ${props => ns(props.theme.controlHeightBase * 0.75)};
		padding: 0 ${props => ns(props.theme.gutter)};
		font-size: ${props => ns(props.theme.fz.small1)};
	}

	&.secondary {
		--bg-color: ${gv('backgroundControl')};
		--bg-hover-color: ${gv('backgroundHover')};
		--bg-active-color: ${gv('backgroundSelected')};
		--color: ${gv('textColor')};
		--box-shadow-color: ${gv('controlShadowDefault')};
		--box-shadow-offset: 2px;
		--box-shadow-offset-hover: 6px;
		--box-shadow-offset-active: 2px;
		--border-color: ${gv('backgroundControl')};
		--border-hover-color: ${gv('backgroundHover')};
		--border-active-color: ${gv('backgroundSelected')};
	}
`;

const GenericContainer = styled.div`
	--bg-color: ${gv('appBackgroundColor')};
	--bg-hover-color: ${gv('backgroundShade')};
	--bg-active-color: ${gv('appBackgroundColor')};
	--color: ${gv('textColor')};
	--box-shadow-color: ${gv('controlShadowDefault')};
	--box-shadow-offset: 2px;
	--box-shadow-offset-hover: 6px;
	--box-shadow-offset-active: 2px;
	--border-color: ${gv('borderColorBase')};
	--border-active-color: ${gv('primaryColor')};

	position: relative;
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['box-shadow', 'border-color', 'background-color'],
			props.theme.transitionControl
		)};

	background-color: var(--bg-color);
	color: var(--color);
	box-shadow: var(--box-shadow-offset) var(--box-shadow-offset) 0 0
		var(--box-shadow-color);
	border: 1px solid var(--border-color);
	height: ${props => ns(props.theme.controlHeightBase)};

	&:hover {
		color: var(--color);
		box-shadow: var(--box-shadow-offset-hover) var(--box-shadow-offset-hover) 0
			0 var(--box-shadow-color);
		border-color: var(--border-active-color);
		background-color: var(--bg-hover-color);
	}
	&:active,
	&:focus,
	&.focus {
		border-color: var(--border-active-color);
		background-color: var(--bg-active-color);
		color: var(--color);
		box-shadow: var(--box-shadow-offset-active) var(--box-shadow-offset-active)
			0 0 var(--box-shadow-color);
		transition: none;
	}
`;

const SelectDropdown = styled.span`
	pointer-events: none;
	height: ${props => ns(props.theme.fz.base)};
	width: ${props => ns(props.theme.fz.base)};
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: ${props => ns(props.theme.gutter / 2)};
`;

const SelectContainer = styled(GenericContainer)`
	position: relative;
	select {
		appearance: none;
		height: ${props => ns(props.theme.controlHeightBase - 2)};
		line-height: ${props => ns(props.theme.controlHeightBase - 2)};
		color: var(--color);
		background-color: transparent;
		border: 0 none;
		outline: none;
		width: 100%;
		padding: 0 ${props => ns(props.theme.gutter / 2)};
		padding-right: ${props => ns(props.theme.gutter + props.theme.fz.base)};
	}
`;

const ControlGroupItem = styled.div``;
export const ControlGroup = styled.div`
	${gridContainerCss};
	align-items: center;
	justify-content: flex-start;
	margin-left: 0;
	margin-right: 0;
	> * {
		flex: 1 0 auto;
		margin: ${props => ns(props.theme.gutter / 2)};
	}
`;

const SelectControl = styled.select`
	color: ${gv('textColor')};
	option,
	optgroup {
		color: ${gv('textColor')};
		background-color: ${gv('appBackgroundColor')};
	}
`;

export function Select(
	props: React.DetailedHTMLProps<
		React.SelectHTMLAttributes<HTMLSelectElement>,
		HTMLSelectElement
	>
) {
	const [focused, setFocused] = useState<boolean>(false);
	const { onFocus, onBlur, ...selectProps } = props;
	return (
		<ControlGroupItem>
			<SelectContainer className={classNames({ focus: focused })}>
				<GenericSkewReverse>
					<SelectControl
						{...(selectProps as any)}
						onFocus={e => {
							setFocused(true);
							onFocus?.(e);
						}}
						onBlur={e => {
							setFocused(false);
							onBlur?.(e);
						}}
					/>
					<SelectDropdown>
						<FontAwesomeIcon icon={faChevronDown} />
					</SelectDropdown>
				</GenericSkewReverse>
			</SelectContainer>
		</ControlGroupItem>
	);
}

const InputIcon = styled(SelectDropdown)`
	right: auto;
	left: ${props => ns(props.theme.gutter / 2)};
	color: ${gv('textColorSecondary')};
	line-height: 1;
`;

const TextContainer = styled(GenericContainer)`
	position: relative;
	input:not([type='radio']),
	input:not([type='checkbox']),
	input:not([type='button']),
	input:not([type='submit']),
	input:not([type='date']),
	input:not([type='datetime-local']),
	input[type='search'],
	input[type='email'],
	input[type='text'],
	button,
	textarea {
		${buttonResetCss};
		appearance: none;
		height: ${props => ns(props.theme.controlHeightBase - 2)};
		line-height: ${props => ns(props.theme.controlHeightBase - 2)};
		background-color: transparent;
		border: 0 none;
		outline: none;
		width: 100%;
		padding: 0 ${props => ns(props.theme.gutter / 2)};
		padding-left: ${props => ns(props.theme.fz.base + props.theme.gutter)};
		color: ${gv('textColor')};
		cursor: text;
		&[type='number'] {
			padding-right: 0;
		}

		&::-webkit-calendar-picker-indicator {
			filter: brightness(50%);
		}
	}
	button {
		white-space: pre;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	textarea {
		resize: none;
		height: 180px;
	}
	&.wpeform-textarea {
		height: 180px;
		${InputIcon} {
			top: ${props => ns(props.theme.gutter / 2)};
			transform: none;
		}
	}
	&.focus {
		${InputIcon} {
			color: ${gv('textColor')};
		}
	}
	&.big {
		height: ${props => ns(props.theme.controlHeightBase * 1.5)};
		input[type='search'],
		input[type='email'],
		input[type='text'],
		button {
			height: ${props => ns(props.theme.controlHeightBase * 1.5 - 2)};
			line-height: ${props => ns(props.theme.controlHeightBase * 1.5 - 2)};
			padding-left: ${props =>
				ns(props.theme.fz.base + props.theme.gutter * 1.5)};
		}
		${InputIcon} {
			left: ${props => ns(props.theme.gutter * 0.75)};
		}
	}
`;

function Text(
	props: React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> & { icon: typeof faChevronDown; controlSize?: 'regular' | 'big' },
	ref: any
) {
	const [focused, setFocused] = useState<boolean>(false);
	const {
		onFocus,
		onBlur,
		icon,
		controlSize = 'regular',
		...inputProps
	} = props;
	return (
		<ControlGroupItem className={controlSize}>
			<TextContainer className={classNames({ focus: focused }, controlSize)}>
				<GenericSkewReverse>
					<input
						{...inputProps}
						onFocus={e => {
							setFocused(true);
							onFocus?.(e);
						}}
						onBlur={e => {
							setFocused(false);
							onBlur?.(e);
						}}
						ref={ref}
					/>
					<InputIcon>
						<FontAwesomeIcon icon={icon} />
					</InputIcon>
				</GenericSkewReverse>
			</TextContainer>
		</ControlGroupItem>
	);
}

function Textarea(
	props: React.DetailedHTMLProps<
		React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	> & { icon: typeof faChevronDown; controlSize?: 'regular' | 'big' },
	ref: any
) {
	const [focused, setFocused] = useState<boolean>(false);
	const {
		onFocus,
		onBlur,
		icon,
		controlSize = 'regular',
		...inputProps
	} = props;
	return (
		<ControlGroupItem className={classNames(controlSize, 'wpeform-textarea')}>
			<TextContainer
				className={classNames(
					{ focus: focused },
					controlSize,
					'wpeform-textarea'
				)}
			>
				<GenericSkewReverse>
					<textarea
						{...inputProps}
						onFocus={e => {
							setFocused(true);
							onFocus?.(e);
						}}
						onBlur={e => {
							setFocused(false);
							onBlur?.(e);
						}}
						ref={ref}
					/>
					<InputIcon>
						<FontAwesomeIcon icon={icon} />
					</InputIcon>
				</GenericSkewReverse>
			</TextContainer>
		</ControlGroupItem>
	);
}

const TextWithRef = forwardRef(Text);
export { TextWithRef as Text };
const TextareaWithRef = forwardRef(Textarea);
export { TextareaWithRef as Textarea };

export function FakeText(
	props: PropsWithChildren<{
		icon: typeof faChevronDown;
		onClick: () => void;
		size?: 'regular' | 'big';
		title?: string;
	}>
) {
	const [focused, setFocused] = useState<boolean>(false);
	const { icon, children, onClick, size = 'regular', title } = props;
	return (
		<ControlGroupItem className={size}>
			<TextContainer className={classNames({ focus: focused }, size)}>
				<GenericSkewReverse>
					<button
						onClick={e => {
							e.preventDefault();
							onClick();
						}}
						onFocus={e => {
							setFocused(true);
						}}
						onBlur={e => {
							setFocused(false);
						}}
						title={title}
					>
						{children}
					</button>
					<InputIcon>
						<FontAwesomeIcon icon={icon} />
					</InputIcon>
				</GenericSkewReverse>
			</TextContainer>
		</ControlGroupItem>
	);
}

const Grid = styled.div`
	display: grid;
	grid-gap: ${props => ns(props.theme.gutter * 0.75)};
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	margin: ${props => ns(props.theme.gutter * 1.5)} 0;
`;

const GridChildHalf = styled.div`
	grid-column: auto / span 6;
	@media ${props => bp(props, 'tablet')} {
		grid-column: auto / span 3;
	}
`;

const GridChildThird = styled.div`
	grid-column: auto / span 6;
	@media ${props => bp(props, 'tablet')} {
		grid-column: auto / span 2;
	}
`;
const GridChildFull = styled.div`
	grid-column: auto / span 6;
`;

const Title = styled.h4`
	&&&& {
		font-family: ${props => props.theme.fontFamilyHeading};
		font-weight: bold;
		color: ${gv('headingColor')};
		font-size: ${props => ns(props.theme.fz.large1)};
		margin: 0;
		grid-column: auto / span 6;
		padding: 0 0 ${props => ns(props.theme.gutter / 2)} 0;
		border-bottom: 1px solid ${gv('borderColorLight')};
	}
`;

export function ControlGrid(props: PropsWithChildren<{}>) {
	return <Grid>{props.children}</Grid>;
}
ControlGrid.Half = GridChildHalf;
ControlGrid.Third = GridChildThird;
ControlGrid.Full = GridChildFull;
ControlGrid.Title = Title;

const Label = styled.label`
	&&&& {
		display: block;
		margin: 0 0 ${props => ns(props.theme.gutter / 2)} 0;
		> p {
			display: block;
			font-size: ${props => ns(props.theme.fz.base)};
			font-weight: bold;
			font-family: ${props => props.theme.fontFamilyHeading};
			margin: 0 0 ${props => ns(props.theme.gutter / 2)} 0;
			span.required {
				font-size: ${props => ns(props.theme.fz.small2 - 4)};
				margin: 0 0 0 4px;
				vertical-align: top;
				color: ${gv('errorColor')};
			}
		}
	}
`;

const ControlContainer = styled.div`
	margin: 0;
`;

export function ControlLabel(
	props: PropsWithChildren<{
		label: ReactNode;
		htmlFor?: string;
		required?: boolean;
	}>
) {
	const { label, children, htmlFor, required } = props;
	return (
		<ControlContainer>
			<Label htmlFor={htmlFor}>
				<p>
					{label}
					{required ? (
						<span className="required" aria-label="Required">
							<FontAwesomeIcon icon={faAsterisk} />
						</span>
					) : null}
				</p>
				{children}
			</Label>
		</ControlContainer>
	);
}

const CopyContainer = styled.div`
	position: relative;
`;
const CopyText = styled.span`
	position: absolute;
	bottom: -2em;
	left: -20px;
	font-size: ${props => ns(props.theme.fz.small2)};
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['opacity', 'left'],
			props.theme.transitionControl
		)};
	opacity: 0;
	&.active {
		opacity: 1;
		left: 0px;
	}
`;

export function ControlCopyDisplay(props: {
	text: string;
	size?: 'regular' | 'big';
}) {
	const [copied, setCopied] = useState<boolean>(false);
	return (
		<CopyContainer
			onMouseLeave={() => {
				setCopied(false);
			}}
		>
			<FakeText
				title="Click to copy to clipboard"
				icon={copied ? faCheck : faCopy}
				size={props.size}
				onClick={() => {
					copy(props.text, {
						message: 'Press #{key} to copy',
						format: 'text/plain',
					});
					setCopied(true);
				}}
			>
				{props.text}
			</FakeText>
			<CopyText className={classNames({ active: copied })}>
				copied to clipboard!
			</CopyText>
		</CopyContainer>
	);
}
