import React, { useState } from 'react';
import styled from 'styled-components';
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode';
import { gv, ns } from '../../utils';
import { Textarea } from '.';

const Wrapper = styled.div`
	position: relative;
	label {
		display: block;
		cursor: pointer;
		color: ${gv('textColorSecondary')};
		transition: color ${props => props.theme.transition};
		font-size: ${props => ns(props.theme.fz.small1)};
		position: absolute;
		bottom: 0;
		height: 40px;
		line-height: 40px;
		border-top: 1px solid ${gv('borderColorBase')};
		width: 100%;
		left: 0;
		padding: 0 ${props => ns(props.theme.gutter)};
		span.wpeform-admin-textfilereader__highlight {
			transition: color ${props => props.theme.transition};
			color: ${gv('linkColor')};
		}
		&:hover {
			color: ${gv('linkHoverColor')};
			span.wpeform-admin-textfilereader__highlight {
				color: ${gv('linkHoverColor')};
			}
		}
	}
	.wpeform-textarea {
		margin: 0;
		width: 100%;
		flex: 0 0 100%;
		textarea {
			font-family: Menlo, 'Courier New', Courier, monospace;
			font-size: ${props => ns(props.theme.fz.small1)};
			height: 140px;
		}
	}
`;

type TextFileReaderProps = {
	label: React.ReactNode;
	reuploadLabel?: React.ReactNode;
	name: string;
	value: string;
	onChange: (text: string) => void;
	className?: string;
	accept?: string;
};

export default function TextFileReader(props: TextFileReaderProps) {
	const [reading, setReading] = useState(false);
	const [activeFile, setActiveFile] = useState('');
	const {
		label,
		reuploadLabel = 'click to reupload.',
		value,
		onChange,
		accept = 'text/plain, text/json, application/json',
	} = props;
	if (
		typeof window === 'undefined' ||
		!FileReader ||
		!File ||
		!FileList ||
		!window.Blob
	) {
		return (
			<Wrapper className="wpeform-admin-textfilereader">
				<Textarea
					icon={faCode}
					controlSize="regular"
					value={value}
					onChange={e => {
						onChange(e.target.value);
					}}
				/>
				<label>upload is not supported in this browser.</label>
			</Wrapper>
		);
	}
	return (
		<Wrapper className="wpeform-admin-textfilereader">
			<Textarea
				icon={faCode}
				controlSize="regular"
				value={value}
				onChange={e => {
					onChange(e.target.value);
				}}
			/>
			<input
				disabled={reading}
				type="file"
				name={props.name}
				id={props.name}
				accept={accept}
				onChange={e => {
					const files = e.target.files;
					if (files) {
						setReading(true);
						const reader = new FileReader();
						// interested only in the first file here
						const file = files[0];
						reader.onload = () => {
							props.onChange(reader.result as string);
							setReading(false);
							setActiveFile(file.name);
						};
						reader.readAsText(file);
					}
				}}
				value=""
				style={{ display: 'none' }}
			/>
			<label htmlFor={props.name}>
				{activeFile !== '' ? (
					<>
						{activeFile} |{' '}
						<span className="wpeform-admin-textfilereader__highlight">
							{reuploadLabel}
						</span>
					</>
				) : reading ? (
					'please wait...'
				) : (
					<>
						{label}{' '}
						<span className="wpeform-admin-textfilereader__highlight">
							click here.
						</span>
					</>
				)}
			</label>
		</Wrapper>
	);
}
