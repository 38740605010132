import React, {
	cloneElement,
	lazy,
	ReactElement,
	Suspense,
	useEffect,
	useState,
} from 'react';
import styled from 'styled-components';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import classNames from 'classnames';
import Modal from '../Modal';
import PageLoader from '../PageLoader';
import { FakeText } from '../Controls';
import { gv, ns } from '../../utils';

const Container = styled.div`
	max-width: 400px;
	margin: 0 auto;
	&.type-big {
		max-width: 600px;
	}
`;

const SearchInner = styled.span`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Hotkey = styled.span`
	padding: 0;
	height: ${props =>
		ns(props.theme.controlHeightBase - props.theme.gutter * 0.75)};
	background-color: ${gv('backgroundHover')};
	width: 14px;
	color: ${gv('textColorSecondary')};
	font-size: ${props => ns(props.theme.fz.small2)};
	display: inline-flex;
	align-items: center;
	justify-content: center;
`;

const DocSearch = lazy(() => import('./DocSearch'));
const SiteSearch = lazy(() => import('./SiteSearch'));

// https://www.gatsbyjs.com/plugins/gatsby-plugin-local-search/
export type SearchProps = {
	uiType?: 'big' | 'regular' | 'hotkey';
	searchScope: 'site' | 'docs';
	children?: ReactElement<{
		onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	}>;
	label?: string;
};
export default function Search(props: SearchProps) {
	const {
		uiType = 'regular',
		searchScope = 'site',
		children,
		label = 'Search…',
	} = props;
	const [showSearch, setShowSearch] = useState<boolean>(false);
	const isSSR = typeof window === 'undefined';

	useEffect(() => {
		if (uiType !== 'hotkey') {
			return () => {};
		}
		if (typeof window !== 'undefined') {
			const handler = (event: KeyboardEvent) => {
				if (event.key === '/') {
					const currentTarget = event.target as HTMLElement;
					// bail if coming from input or textarea
					if (
						currentTarget?.tagName === 'INPUT' ||
						currentTarget?.tagName === 'TEXTAREA'
					) {
						return;
					}
					// bail if one modal is already open
					if (document.body.classList.contains('ReactModal__Body--open')) {
						return;
					}
					// finally show the search
					if (!showSearch) {
						event.preventDefault();
						setShowSearch(true);
					}
				}
			};
			window.addEventListener('keydown', handler);
			return () => {
				window.removeEventListener('keydown', handler);
			};
		}
		return () => {};
	}, [uiType, showSearch]);

	return (
		<>
			{children ? (
				cloneElement(children, {
					onClick(e: any) {
						e.preventDefault();
						setShowSearch(true);
					},
				})
			) : (
				<Container className={classNames({ [`type-${uiType}`]: true })}>
					<FakeText
						onClick={() => {
							setShowSearch(true);
						}}
						icon={faSearch}
						size={uiType === 'big' ? 'big' : 'regular'}
					>
						{uiType === 'hotkey' ? (
							<SearchInner>
								<span>{label}</span>
								<Hotkey>/</Hotkey>
							</SearchInner>
						) : (
							label
						)}
					</FakeText>
				</Container>
			)}
			<Modal
				isOpen={showSearch}
				setIsOpen={setShowSearch}
				type="regular"
				maxWidth="600px"
			>
				{!isSSR ? (
					<Suspense
						fallback={
							<PageLoader>Please wait while the search is loaded.</PageLoader>
						}
					>
						{searchScope === 'site' ? <SiteSearch /> : <DocSearch />}
					</Suspense>
				) : null}
			</Modal>
		</>
	);
}
