import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { bp, gv, ns, proseCss } from '../../utils';

export const PageTitle = styled.h1`
	font-size: ${props => ns(props.theme.fz.large4)};
	line-height: 1.4;
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.tablet)}) {
		font-size: ${props => ns(props.theme.fz.large5)};
	}
	padding: 0 0 1em 0;
	margin: 0 0 1em 0;
	font-family: ${props => props.theme.fontFamilyHeading};
	position: relative;
	color: ${gv('headingColor')};
	font-weight: bold;
	&::after {
		content: '';
		position: absolute;
		bottom: -3px;
		left: 0;
		width: 50%;
		max-width: 150px;
		height: 6px;
		background-color: ${gv('headingColor')};
		border-radius: 3px;
	}
	&.centered {
		text-align: center;
		&::after {
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&.narrow {
		max-width: 760px;
		margin-left: auto;
		margin-right: auto;
	}
	&.blog-title {
		padding: 0;
		&::after {
			display: none;
		}
	}
`;

export const PageSubTitle = styled.h3`
	font-size: ${props => ns(props.theme.fz.large2)};
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.tablet)}) {
		font-size: ${props => ns(props.theme.fz.large3)};
	}
	padding: 0 0 1em 0;
	margin: 0 auto 1em;
	font-family: ${props => props.theme.fontFamilyHeading};
	color: ${gv('headingColor')};
	font-weight: normal;
	line-height: 1.5;
	&.centered {
		max-width: 680px;
		text-align: center;
	}
`;

export const Article = styled.article`
	${proseCss};
	&.centered {
		text-align: center;
	}
	&.subheader {
		text-align: center;
		max-width: 600px;
		line-height: 1.5;
		color: ${gv('textColorSecondary')};
		margin: 0 auto;
	}
	&.has-gutter {
		margin-top: ${props => ns(props.theme.gutter)};
		margin-bottom: ${props => ns(props.theme.gutter)};
	}
`;

const HighlighterChildren = styled.span`
	position: relative;
`;

const Highlighter = styled.span`
	position: relative;
	display: inline-block;
	color: inherit;

	&::before {
		bottom: 0.075em;
		left: -0.1em;
		right: -0.1em;
		height: 0.4em;
		position: absolute;
		content: '';
		display: block;
		background-color: rgb(252, 229, 136, 0.5);
		transform: skewY(-1deg);
		transform-origin: center center;
	}
`;

export function Highlight(props: PropsWithChildren<{}>) {
	const { children } = props;

	return (
		<Highlighter className="pen-stroke">
			<HighlighterChildren>{children}</HighlighterChildren>
		</Highlighter>
	);
}

export const Icon = styled.span`
	svg {
		display: inline-block;
		height: 1em;
		width: 1em;
		vertical-align: middle;
	}
`;

export const GradientHeading = styled.p`
	text-transform: uppercase;
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.base)};
	color: ${gv('secondaryAccentColor')};
	font-weight: bold;
	padding: 0;
	margin: 0 auto ${props => ns(props.theme.gutter * 0.5)} auto;
	text-align: center;
	span {
		background-image: linear-gradient(
			to right,
			${gv('secondaryAccentColor')},
			${gv('accentColor')}
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		display: inline-block;
	}
`;

export const SectionHeading = styled.h2`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.large3)};
	line-height: 1.5;
	text-align: center;
	font-weight: bold;
	color: ${gv('headingColor')};
	margin: 0 auto ${props => ns(props.theme.gutter)} auto;
	max-width: 600px;
	@media ${props => bp(props, 'tablet')} {
		font-size: ${props => ns(props.theme.fz.large4)};
		line-height: 1.3;
		margin-bottom: ${props => ns(props.theme.gutter * 1.5)};
	}
	@media ${props => bp(props, 'desktop')} {
		font-size: ${props => ns(props.theme.fz.large5)};
		margin-bottom: ${props => ns(props.theme.gutter * 2)};
	}
`;

export const Divider = styled.div`
	width: 50%;
	max-width: 150px;
	height: 6px;
	background-color: ${gv('borderColorBase')};
	border-radius: 3px;
	margin: ${props => ns(props.theme.gutter * 2)} auto;
`;

export const Description = styled.p`
	margin: 0;
	font-size: ${props => ns(props.theme.fz.base)};
	&.has-gutter {
		margin: ${props => ns(props.theme.gutter * 2)} auto;
	}
	&.centered {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		max-width: 600px;
	}
`;

export const Highlighted = styled.blockquote`
	margin: ${props => ns(props.theme.gutter)} auto;
	background-color: ${gv('backgroundSelected')};
	border: 1px solid ${gv('borderColorSplit')};
	font-size: ${props => ns(props.theme.fz.large1)};
	text-align: center;
	font-family: ${props => props.theme.fontFamilyMono};
	padding: ${props => ns(props.theme.gutter)};
	color: ${gv('textColor')};
	@media ${props => bp(props, 'tablet')} {
		font-size: ${props => ns(props.theme.fz.large3)};
	}
`;

export const Badge = styled.span`
	display: inline-block;
	font-size: ${props => ns(props.theme.fz.small2)};
	font-weight: bold;
	color: ${gv('textColorSecondary')};
	background-color: ${gv('backgroundSelected')};
	padding: 3px 5px;
	margin: 0.25em 0.5em;
`;
