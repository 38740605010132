import { paramCase } from 'change-case';
import { lightColor } from './light';
import { darkColor } from './dark';
import { ColorVariablesType } from './types';

// add basic variables
let cssVariables = ':root {';
cssVariables += Object.keys(lightColor).reduce((acc, current) => {
	return `${acc} --${paramCase(current)}:${
		lightColor[current as keyof ColorVariablesType]
	};`;
}, '');
cssVariables += '} body.dark {';
cssVariables += Object.keys(darkColor).reduce((acc, current) => {
	return `${acc} --${paramCase(current)}:${
		darkColor[current as keyof ColorVariablesType]
	};`;
}, '');
cssVariables += '}';

export { cssVariables };
